(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('TournamentRoundPositionsBattleRoyalController', TournamentRoundPositionsBattleRoyalController);

        TournamentRoundPositionsBattleRoyalController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Tournament', 'Organization', 'Team', 'Player', 'Round','$window','CurrentNavigationApp','StandingsByTournamentSearch','TournamentBracket','Seed'];

    function TournamentRoundPositionsBattleRoyalController($scope, $rootScope, $stateParams, previousState, entity, Tournament, Organization, Team, Player, Round,$window,CurrentNavigationApp,StandingsByTournamentSearch,TournamentBracket,Seed) {
        var vm = this;

        vm.tournament = entity;
        vm.previousState = previousState.name;

        $scope.roundPositionList = [];

        TournamentBracket.getRoundPositionsBattleRoyalFromTournament({id:  vm.tournament.id}, onGetRoundEventSuccess, onSaveError);

      
        
        CurrentNavigationApp.addTournament(vm.tournament.id,vm.tournament.name, vm.tournament.playersOrTeams, vm.tournament.tournamentType);
        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();
        
      	
        var unsubscribe = $rootScope.$on('ldtpApp:tournamentUpdate', function(event, result) {
            vm.tournament = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        vm.isSaving = false; 
        
        vm.save = function () {    
            if (vm.tournament.id !== null) {                              
                TournamentBracket.saveRoundPositionsBattleRoyalFromTournament({id:  vm.tournament.id},  $scope.roundPositionList,onSaveSuccess, onSaveError);
            } 
           
        }
        

        function onSaveSuccess (result) {
           // $scope.$emit('ldtpApp:tournamentUpdate', result);
           // $uibModalInstance.close(result);
            vm.isSaving = false;
            $window.location= "/#/tournament/tournament/" +vm.tournament.id;  
            $window.location.reload();
        }

        function onGetRoundEventSuccess (result) {
            if (result !== null && result.length > 0){
                $scope.roundPositionList = result;
            }else {
                if ( vm.tournament.tournamentType === 'LEADERBOARD'){
                    if (vm.tournament.totalParticipants !== null){                    
                        for (var index=0; index < vm.tournament.brRowsToFillPositions; index++){
                            var item = {position:(index + 1),points: null};
                            $scope.roundPositionList.push(item);
                        }                    
                    }
                }
                
                
            }
         
           
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
 
    }
})();
