(function () {
    'use strict';
    angular
        .module('ldtpApp')
        .factory('MathCommonUtils', MathCommonUtils);

    MathCommonUtils.$inject = ['$resource', 'DateUtils'];

    function MathCommonUtils($resource, DateUtils) {
        var resourceUrl = 'api/matchs/:command/:id';

        return $resource(resourceUrl, {
            command: '@command'

        }, {
            
            'updateMatchDate': {
                method: 'POST',
                params: {
                    command: 'save-date',
                    id: '@id',
                    matchDate: '@matchDate'
                }
            },
            'updateMatchComment': {
                method: 'POST',
                params: {
                    command: 'save-comment',
                    id: '@id',
                    matchComment: '@matchComment'
                }
            },
            'updateMatchFormation': {
                method: 'POST',
                params: {
                    command: 'save-formation',
                    id: '@id',
                    matchFormation: '@matchFormation'
                }
            },
            'updateMatchFormationTwo': {
                method: 'POST',
                params: {
                    command: 'save-formation-two',
                    id: '@id',
                    matchFormationTwo: '@matchFormationTwo'
                }
            }
            ,
            'updateMatchStreaming': {
                method: 'POST',
                params: {
                    command: 'save-streaming',
                    id: '@id',
                    matchStreaming: '@matchStreaming'
                }
            },
            'updateMatchNowPlaying': {
                method: 'POST',
                params: {
                    command: 'save-nowplaying',
                    id: '@id',
                    matchnowplaying: '@matchnowplaying'
                }
            },
            'updateMatchStreamDescription': {
                method: 'POST',
                params: {
                    command: 'save-stream-description',
                    id: '@id',
                    matchStreamDescription: '@matchStreamDescription'
                }
            },
            'updateMatchLobbyCode': {
                method: 'POST',
                params: {
                    command: 'save-lobby-code',
                    id: '@id',
                    matchLobbyCode: '@matchLobbyCode'
                }
            },
            'updateGameProgressInMatch': {
                method: 'POST',
                params: {
                    command: 'update-game-progress',
                    id: '@id',
                    newGameProgress: '@newGameProgress'
                },
                isArray: true 
            },
            'updateMatchMakeBye': {
                method: 'POST',
                params: {
                    command: 'update-make-bye',
                    id: '@id'
                }
            }
        });
    }
})();
