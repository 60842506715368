(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .factory('TeamScoreBoardByTournament', TeamScoreBoardByTournament);

    TeamScoreBoardByTournament.$inject = ['$resource'];

    function TeamScoreBoardByTournament($resource) {
        var resourceUrl =  'api/score-boards/team/:teamId/tournament/:tourId';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false}
        });
    }
})();
