(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .factory('StandingsByTournamentSearch', StandingsByTournamentSearch);

    StandingsByTournamentSearch.$inject = ['$resource'];

    function StandingsByTournamentSearch($resource) {
        var resourceUrl =  'api/score-boards/tournament/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
