(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('ScoreBoardDeleteController',ScoreBoardDeleteController);

    ScoreBoardDeleteController.$inject = ['$uibModalInstance', 'entity', 'ScoreBoard'];

    function ScoreBoardDeleteController($uibModalInstance, entity, ScoreBoard) {
        var vm = this;

        vm.scoreBoard = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ScoreBoard.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
