(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('RoundLeaderboardController',RoundLeaderboardController);

        RoundLeaderboardController.$inject = [ '$scope','$window','entity', 'Round', 'CurrentNavigationApp'];

    function RoundLeaderboardController($scope,$window, entity, Round, CurrentNavigationApp) {
        var vm = this;

        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();
        vm.leaderboardItems = entity;
        vm.clear = clear;
        vm.save = save;
        //console.log("Info entoty get ledebpard >" +JSON.stringify(entity));

        function clear () {
            //$uibModalInstance.dismiss('cancel');
        }

        function save () {
            Round.bulkUpdateLeaderboard(vm.leaderboardItems, onSaveSuccess, onSaveError);
         /*   Round.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
           */
        	 //$uibModalInstance.close(true);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
           
            $window.location = "/#/tournament/tournament/" + $scope.currentNavigation .Tournament_id;
            $window.location.reload();
            console.log(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
