(function() {
    'use strict';
    angular
        .module('ldtpApp')
        .factory('TournamentDeckService', TournamentDeckService);

    TournamentDeckService.$inject = ['$resource', 'DateUtils'];

    function TournamentDeckService ($resource, DateUtils) {
        var resourceUrl =  'api/decks/:command/:deckId';

        return $resource(resourceUrl, {
        	command: '@command'        		
        }, {
          
            'saveDeckCardList': {method:'POST', params: {command: 'save-cards', deckId: '@deckId'}}
            ,'getDeckCardList': {method:'GET', params: {command: 'list-cards', deckId: '@deckId'},isArray: true}
            ,'loadLastDeckCardList': {method:'POST', params: {command: 'load-last-cards', deckId: '@deckId'}}
        });
    }
})();
