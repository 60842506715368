(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('GameSeasonDetailController', GameSeasonDetailController);

    GameSeasonDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'GameSeason'];

    function GameSeasonDetailController($scope, $rootScope, $stateParams, previousState, entity, GameSeason) {
        var vm = this;

        vm.gameSeason = entity;
        vm.previousState = previousState.name;


        var unsubscribe = $rootScope.$on('ldtpApp:gameSeasonUpdate', function(event, result) {
            vm.gameSeason = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
