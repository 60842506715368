(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .directive('inlineEdit', inlineEdit);

    inlineEdit.$inject = ['$timeout'];

    function inlineEdit($timeout) {
    	  return {
    		    scope: {
    		      model: '=inlineEdit',
    		      handleSave: '&onSave',
    		      handleCancel: '&onCancel'
    		    },
    		    link: function(scope, elm, attr) {
    		      var previousValue;
    		      
    		      scope.edit = function() {
    		        scope.editMode = true;
    		        previousValue = scope.model;
    		        
    		        $timeout(function() {
    		          elm.find('input')[0].focus();
    		        }, 0, false);
    		      };
    		      scope.save = function() {
    		        scope.editMode = false;
    		        scope.handleSave({value: scope.model});
    		      };
    		      scope.cancel = function() {
    		        scope.editMode = false;
    		        scope.model = previousValue;
    		        scope.handleCancel({value: scope.model});
    		      };
    		    },
    		    template: '<div> <input type="text" size="40" on-enter="save()" on-esc="cancel()" ng-model="model" ng-show="editMode"> <button ng-click="cancel()" ng-show="editMode">cancel</button> <button ng-click="save()" ng-show="editMode">save</button> <span ng-mouseenter="showEdit = true" ng-mouseleave="showEdit = false"> <span ng-hide="editMode" ng-click="edit()" style="font-size: 1.5em;font-weight: bold;">{{model}}</span> <a ng-show="showEdit" ng-click="edit()">edit</a> </span> </div>'
    		  };
    }
})();


(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .directive('onEnter', onEnter);

    function onEnter() {
    	  return function(scope, elm, attr) {
    		    elm.bind('keypress', function(e) {
    		      if (e.keyCode === 13) {
    		        scope.$apply(attr.onEnter);
    		      }
    		    });
    		  };
    }
})();



(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .directive('onEsc', onEsc);

    function onEsc() {
    	  return function(scope, elm, attr) {
    		    elm.bind('keydown', function(e) {
    		      if (e.keyCode === 27) {
    		        scope.$apply(attr.onEsc);
    		      }
    		    });
    		  };
    }
})();

