(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .factory('RoundRobinByTournamentService', RoundRobinByTournamentService);

    RoundRobinByTournamentService.$inject = ['$resource'];

    function RoundRobinByTournamentService($resource) {
        var resourceUrl =  'api/tournaments/round/:id/round-robin';

        return $resource(resourceUrl, {id:'@id'}, {
           
            'generateAllRoundRobin': { method:'POST'
            	, params: {                     
                    id: '@id'
                }
            },
            'generateAllCustomRoundRobin': { method:'PUT'
            	, params: {                     
                    id: '@id'
                }
            },
            'generateCustomSingleElimination': { method:'PATCH'
            	, params: {                     
                    id: '@id'
                }
            }
        });
    }
})();
