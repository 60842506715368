(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('seed', {
            parent: 'entity',
            url: '/seed',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.seed.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/seed/seeds.html',
                    controller: 'SeedController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('seed');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('seed-detail', {
            parent: 'seed',
            url: '/seed/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.seed.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/seed/seed-detail.html',
                    controller: 'SeedDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('seed');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Seed', function($stateParams, Seed) {
                    return Seed.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'seed',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('seed-detail.edit', {
            parent: 'seed-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/seed/seed-dialog.html',
                    controller: 'SeedDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Seed', function(Seed) {
                            return Seed.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('seed.new', {
            parent: 'seed',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/seed/seed-dialog.html',
                    controller: 'SeedDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                seed: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('seed', null, { reload: 'seed' });
                }, function() {
                    $state.go('seed');
                });
            }]
        })
        .state('seed.edit', {
            parent: 'seed',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/seed/seed-dialog.html',
                    controller: 'SeedDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Seed', function(Seed) {
                            return Seed.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('seed', null, { reload: 'seed' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('seed.delete', {
            parent: 'seed',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/seed/seed-delete-dialog.html',
                    controller: 'SeedDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Seed', function(Seed) {
                            return Seed.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('seed', null, { reload: 'seed' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
