(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('TeamDialogController', TeamDialogController);

    TeamDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Team', 'Organization', 'Player', 'Tournament','PlayerByOrganizationSearch'];

    function TeamDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Team, Organization, Player, Tournament,PlayerByOrganizationSearch) {
        var vm = this;

        vm.team = entity;
        vm.clear = clear;
        vm.save = save;
        vm.organizations = Organization.query();
        
        if (!vm.team.players){
        	vm.team.players  =[];
        }
        vm.players = vm.team.players ; //Player.query();
        vm.tournaments = Tournament.query();

       // $scope.PlayerByOrganizationAvailable = PlayerByOrganizationSearch.query(onGetPlayersSuccess);
       $scope.PlayerByOrganizationAvailable = Player.query({
        page: 0,
        size: 10000,
        sort:  ['gamerTag' + ',' + 'asc']
        },onGetPlayersSuccess);
       
       
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.team.id !== null) {
                Team.update(vm.team, onSaveSuccess, onSaveError);
            } else {
                Team.save(vm.team, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ldtpApp:teamUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        function onGetPlayersSuccess (result) {
          
            removeListFromArray($scope.PlayerByOrganizationAvailable,vm.players);
        }
        
        $scope.moveItem = function (item, from, to) {
            console.log('Move item   Item: '+item+' From:: '+from+' To:: '+to);
            //Here from is returned as blank and to as undefined
            
            var found = from.find(  function(itemArray){
            							return itemArray.id === item.id
            						}
            					);

            var idx=from.indexOf(found);
            if (idx != -1) {
                from.splice(idx, 1);
                to.push(item);      
            }
        };
        
        $scope.moveAll = function (from, to) {

            console.log('Move all  From:: '+from+' To:: '+to);
            //Here from is returned as blank and to as undefined

            angular.forEach(from, function(item) {
                to.push(item);
            });
            from.length = 0;
        };
        
        function removeListFromArray(listTotal, listExcept){
        	angular.forEach(listExcept, function(item, index) {
        		// console.log(item, index);        		  
        		  var found = listTotal.find(  function(itemArray){
									return itemArray.id === item.id
								}
        		  			);

        		  var idx= listTotal.indexOf(found);
        		  if (idx != -1) {
        			  	listTotal.splice(idx, 1);
        			 // 	to.push(item);      
        		  }
        		  
        		  
        		});
        	
        };
        
    }
})();
