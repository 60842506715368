(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('PlayersLoaderDialogController', PlayersLoaderDialogController);

    PlayersLoaderDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'PlayersLoader', 'User', 'Organization'];

    function PlayersLoaderDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, PlayersLoader, User, Organization) {
        var vm = this;

        vm.playersLoader = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.users = User.query();
        vm.organizations = Organization.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.playersLoader.id !== null) {
                PlayersLoader.update(vm.playersLoader, onSaveSuccess, onSaveError);
            } else {
                PlayersLoader.save(vm.playersLoader, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ldtpApp:playersLoaderUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setPlayersList = function ($file, playersLoader) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        playersLoader.playersList = base64Data;
                        playersLoader.playersListContentType = $file.type;
                    });
                });
            }
        };
        vm.datePickerOpenStatus.loadDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
