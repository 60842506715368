(function() {
    'use strict';
    angular
        .module('ldtpApp')
        .factory('CurrentNavigationApp', CurrentNavigationApp);

    CurrentNavigationApp.$inject = ['$cookies'];

    function CurrentNavigationApp ($cookies) {
    	 var service = {
    			 addTournament : addTournament,
    			 getCurrentNavigation:getCurrentNavigation,
    			 addRound:addRound,
    			 addMatch:addMatch,
    			 clear:clear
    	 };

    	 return service;
    	 
    	 function addTournament(tournamentId,tournamentName,tourType,newTourType){
    		 var navObj = {"Tournament_id":tournamentId, "Tournament_name":tournamentName,"Tournament_type":tourType,"New_Tournament_type":newTourType};
    		 $cookies.putObject('CurrentNavigation', navObj);
    		
    	 }
    	 function addRound(roundId,roundName){
    		var navObj =  getCurrentNavigation();
    		if (navObj != null && navObj.Tournament_id != null){
    			navObj.Round_id = roundId;
    			navObj.Round_name = roundName;
    			 $cookies.putObject('CurrentNavigation', navObj);
    		}
    		 
    	 }
    	 
    	 function addMatch(matchId,matchName){
    		 var navObj =  getCurrentNavigation();
     		if (navObj != null && navObj.Tournament_id != null && navObj.Round_id != null && navObj.Round_name != null){
     			navObj.Match_id = matchId;
    			navObj.Match_name = matchName;
    			 $cookies.putObject('CurrentNavigation', navObj);
     		}
    		 
    	 }
    	 
    	 function getCurrentNavigation(){
    		 return $cookies.getObject('CurrentNavigation')
    	 }
    
    	 function clear(){
    		 $cookies.remove('CurrentNavigation');
    	 }
    }
})();
