(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .factory('TeamHistoryByTournament', TeamHistoryByTournament);

    TeamHistoryByTournament.$inject = ['$resource'];

    function TeamHistoryByTournament($resource) {
        var resourceUrl =  'api/tournaments/:tourId/team/:teamId/history';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false}
        });
    }
})();
