(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('TournamentDeckCardListController',TournamentDeckCardListController);

    TournamentDeckCardListController.$inject = ['$uibModalInstance', 'entity', '$scope','TournamentDeckService','$stateParams'];

    function TournamentDeckCardListController($uibModalInstance, entity, $scope,TournamentDeckService,$stateParams) {
        var vm = this;

       // vm.tournament = entity;
        vm.clear = clear;
        vm.addNewCard= addNewCard;
        vm.removeCard= removeCard;
        vm.deckId = $stateParams.deckId;
        
        $scope.deckCardList =entity;
        
       
        //TournamentDeckService.getDeckCardList({deckId:  vm.tournament.id},onGetCardsSuccess, onSaveError);
        
        $scope.divLoading = 'none';
       
        
        function clear () {
           // $uibModalInstance.dismiss('cancel');
           $uibModalInstance.close();
        }

        
        
       
        
        function onSaveSuccess (result) {
           
            $uibModalInstance.close();
            $scope.divLoading = 'none';
        }
        
        function onSaveError () {
        	 $scope.divLoading = 'none';
        }
        
        function addNewCard(){
            $scope.deckCardList.push({ 'cardName': ''});
        }

        function removeCard(index){
            $scope.deckCardList.splice(index, 1);
        }
        
        vm.save = function () {                                           
            TournamentDeckService.saveDeckCardList({deckId: vm.deckId }, $scope.deckCardList,onSaveSuccess, onSaveError);                      
        }
       
        function onGetCardsSuccess (result) {
            if (result !== null && result.length > 0){
                $scope.deckCardList = result;
            }

        }
    }
})();
