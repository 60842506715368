(function () {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('RoundReworkController', TournamentInitialRoundDetailController);

    TournamentInitialRoundDetailController.$inject = [
        "$q", '$scope', '$rootScope', '$stateParams',
        'previousState', 'entity', 'Tournament',
        'Organization', 'Team', 'Player',
        'Round', '$window', 'CurrentNavigationApp',
        'Matchs','TournamentBracket'];

    function TournamentInitialRoundDetailController($q, $scope, $rootScope, $stateParams,
                                                    previousState, entity, Tournament,
                                                    Organization, Team, Player,
                                                    Round, $window, CurrentNavigationApp,
                                                    Matchs,TournamentBracket) {
        var vm = this;

        vm.round = entity;
        vm.previousState = previousState.name;

        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();

        var playerGroups = entity.matches.map(function (match) {
            return [match.playerOne, match.playerTwo]
        });
        vm.players = [].concat.apply([], playerGroups);

        var teamGroups = entity.matches.map(function (match) {
            return [match.teamOne, match.teamTwo]
        });
        vm.teams = [].concat.apply([], teamGroups);
        
        if( $scope.currentNavigation != null && $scope.currentNavigation.Tournament_id != null ){
        	Tournament.get({id:$scope.currentNavigation.Tournament_id },onGetTorunamentSuccess);
        }

        vm.matchSavedFlag = false;
       
        
        function onSuccessGslGroups (result) {            
            $.each(result, function( index, groupGSL ) {
                if ( groupGSL && groupGSL.name === vm.round.deRoundType) {
                    const newArray = new Map();
                    $.each( vm.players, function( index, player ) {
                        if (player){
                            newArray.set(player.id, player);
                        }
                        
                    });
                    $.each( groupGSL.players, function( index, player ) {
                        if (player){
                            newArray.set(player.id, player);
                        }
                        
                    });                 
                   vm.players = Array.from(newArray.values());
                    
                  
               }                
            });
          
        }

        function onSuccessGslGroupsForTeams (result) {            
            $.each(result, function( index, groupGSL ) {
                if ( groupGSL && groupGSL.name === vm.round.deRoundType) {
                    const newArray = new Map();
                    $.each( vm.teams, function( index, team ) {
                        if (team){
                            newArray.set(team.id, team);
                        }
                       
                    });
                    $.each( groupGSL.teams, function( index, team ) {
                        if (team){
                            newArray.set(team.id, team);
                        }
                       
                    });                 
                   vm.teams = Array.from(newArray.values());
                    
                  
               }                
            });
          
        }

        function onSaveErrorsStandings () {
         	
        }

        function validateMatchs() {
            var matchs = vm.round.matches;//$scope.customMatch;

            var flagPass = false;
            if (matchs != null && matchs.length > 0) {
                var players = vm.players;//vm.round.players;
                for (var indexPlay = 0; indexPlay < players.length; indexPlay++) {
                    var idPlay = players[indexPlay].id;
                    var countUsed = 0;
                    var msg = "";
                    for (var indexMatch = 0; indexMatch < matchs.length; indexMatch++) {
                    	if (matchs[indexMatch].playerOne == null || matchs[indexMatch].playerTwo == null){
                    		continue;
                    	}
                        var matchPlayOneId = matchs[indexMatch].playerOne.id;
                        var matchPlayTwoId = matchs[indexMatch].playerTwo.id;

                        if (idPlay === matchPlayOneId) {
                            countUsed++;
                            msg += " Match " + (indexMatch + 1);
                        }
                        if (idPlay === matchPlayTwoId) {
                            countUsed++;
                            msg += " Match " + (indexMatch + 1);
                        }
                    }
                    if (countUsed > 1) {
                        alert("The Gamer " + players[indexPlay].gamerTag + " was used in " + countUsed + " times" + "[" + msg + "]");

                        return flagPass;
                    }
                }
                flagPass = true;

            }
            return flagPass;
        }
        function validateMatchsForTeams() {
            var matchs = vm.round.matches;//$scope.customMatch;

            var flagPass = false;
            if (matchs != null && matchs.length > 0) {
                var teams = vm.teams;//vm.round.players;
                for (var indexTeam = 0; indexTeam < teams.length; indexTeam++) {
                    var idTeam = teams[indexTeam].id;
                    var countUsed = 0;
                    var msg = "";
                    for (var indexMatch = 0; indexMatch < matchs.length; indexMatch++) {
                    	if (matchs[indexMatch].teamOne == null || matchs[indexMatch].teamTwo == null){
                    		continue;
                    	}
                        var matchTeamOneId = matchs[indexMatch].teamOne.id;
                        var matchTeamTwoId = matchs[indexMatch].teamTwo.id;

                        if (idTeam === matchTeamOneId) {
                            countUsed++;
                            msg += " Match " + (indexMatch + 1);
                        }
                        if (idTeam === matchTeamTwoId) {
                            countUsed++;
                            msg += " Match " + (indexMatch + 1);
                        }
                    }
                    if (countUsed > 1) {
                        alert("The Gamer " + teams[indexTeam].name + " was used in " + countUsed + " times" + "[" + msg + "]");

                        return flagPass;
                    }
                }
                flagPass = true;

            }
            return flagPass;
        }
        vm.save = function () {
            vm.matchSavedFlag = false;
            if ( $scope.currentNavigation != null &&  $scope.currentNavigation.Tournament_type =='PLAYERS'){
                var sendToSave = false;
                if ( $scope.currentNavigation && $scope.currentNavigation.New_Tournament_type && ( $scope.currentNavigation.New_Tournament_type === 'DOUBLE_ELIMINATION_FREE' || $scope.currentNavigation.New_Tournament_type == 'BEST_OF_DE'  || $scope.currentNavigation.New_Tournament_type == 'GSL_STYLE' || $scope.currentNavigation.New_Tournament_type === 'FREE_ROUNDS' || $scope.currentNavigation.New_Tournament_type === 'CUSTOM_ROUND_ROBIN' || $scope.currentNavigation.New_Tournament_type === 'VALORANT_CUSTOM_ROUND_ROBIN' || $scope.currentNavigation.New_Tournament_type === 'VALORANT_DOUBLE_ELIMINATION_FREE' || $scope.currentNavigation.New_Tournament_type === 'BEST_OF_FREE')){
                    sendToSave = true;
                }else{
                    sendToSave = validateMatchs();
                }
            
        	    if (sendToSave){
    	            vm.isSaving = true;
	           /* $q.all(
	                $.map(vm.round.matches, function (match) {
	                    match.name = match.playerOne.gamerTag + " vs " + match.playerTwo.gamerTag;
	                    return Matchs.update(match);
	                })).then(function (result) {
	                vm.isSaving = false;
	                onSaveSuccess(vm.round)
	            });
	            */
	                $.each( vm.round.matches, function( index, match ) {
	            	if ( match.playerOne != null  && match.playerTwo != null) {
	            	 match.name = match.playerOne.gamerTag + " vs " + match.playerTwo.gamerTag;
	               }
	            	;
	            	});
	                Matchs.bulkUpdate(vm.round.matches, onSaveSuccess, onSaveError);
                }
            }else{
                var sendToSave = false;
                
                if ( $scope.currentNavigation && $scope.currentNavigation.New_Tournament_type && ( $scope.currentNavigation.New_Tournament_type === 'DOUBLE_ELIMINATION_FREE' || $scope.currentNavigation.New_Tournament_type == 'BEST_OF_DE' || $scope.currentNavigation.New_Tournament_type == 'GSL_STYLE' || $scope.currentNavigation.New_Tournament_type === 'FREE_ROUNDS' || $scope.currentNavigation.New_Tournament_type === 'BEST_OF_FREE'|| $scope.currentNavigation.New_Tournament_type === 'CUSTOM_ROUND_ROBIN' || $scope.currentNavigation.New_Tournament_type === 'VALORANT_CUSTOM_ROUND_ROBIN' || $scope.currentNavigation.New_Tournament_type === 'VALORANT_DOUBLE_ELIMINATION_FREE')){
                    sendToSave = true;
                }else{
                    sendToSave = validateMatchsForTeams();
                }
                
                    
                
            
        	    if (sendToSave){
    	            vm.isSaving = true;
	           
	                $.each( vm.round.matches, function( index, match ) {
	            	if ( match.teamOne != null  && match.teamTwo != null) {
	            	 match.name = match.teamOne.name + " vs " + match.teamTwo.name;
	               }
	            	;
	            	});
	                Matchs.bulkUpdate(vm.round.matches, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.matchSavedFlag = result;
            //$window.location = "/#/round/round/" + result.id;
            $window.location = "/#/round/round/" + vm.round.id;
            $window.location.reload();
            
        }

        function onSaveError() {
            vm.isSaving = false;
        }
        
        function onGetTorunamentSuccess(result) { 
            if (result && result.playersOrTeams ==='PLAYERS'){         
                 if (result.tournamentType == 'CUSTOM_SINGLE_ELIMINATION' || result.tournamentType == 'EMLS_SINGLE_ELIMINATION_FREE' ){
                      vm.players = result.players;                     
                 }
                 if (result.tournamentType == 'DOUBLE_ELIMINATION_FREE' || result.tournamentType === 'BEST_OF_DE'  || result.tournamentType === 'FREE_ROUNDS' || $scope.currentNavigation.New_Tournament_type === 'BEST_OF_FREE' || result.tournamentType === 'CUSTOM_ROUND_ROBIN' || result.tournamentType === 'VALORANT_CUSTOM_ROUND_ROBIN' || $scope.currentNavigation.New_Tournament_type === 'VALORANT_DOUBLE_ELIMINATION_FREE'){

                    const newArray = new Map();
                    $.each( vm.players, function( index, player ) {
                        if (player){
                            newArray.set(player.id, player);
                        }
                        
                    });
                    $.each( result.players, function( index, player ) {
                        if (player){
                            newArray.set(player.id, player);
                        }
                    });
                   /* forEach(function (element) {
                        var item = {player: element, link: null, name:null};
                        $scope.magicDeckList.push(item);
        
                    });
                    */
                   // let jointArray = []
                   // jointArray = [...vm.players, ...]
                   vm.players = Array.from(newArray.values());
                 }

                 if (result.tournamentType === 'GSL_STYLE'){
                    TournamentBracket.groupsRoundRobin({id:  result.id}, onSuccessGslGroups, onSaveErrorsStandings); 
                }
            }else  if (result && result.playersOrTeams ==='TEAMS')      {
                if (result.tournamentType == 'CUSTOM_SINGLE_ELIMINATION' || result.tournamentType == 'EMLS_SINGLE_ELIMINATION_FREE' ){
                    vm.teams = result.teams;                     
               }
               if (result.tournamentType == 'DOUBLE_ELIMINATION_FREE' || result.tournamentType === 'BEST_OF_DE' || result.tournamentType === 'FREE_ROUNDS' || $scope.currentNavigation.New_Tournament_type === 'BEST_OF_FREE' || result.tournamentType === 'CUSTOM_ROUND_ROBIN' || result.tournamentType === 'VALORANT_CUSTOM_ROUND_ROBIN' || $scope.currentNavigation.New_Tournament_type === 'VALORANT_DOUBLE_ELIMINATION_FREE'){

                  const newArray = new Map();
                  $.each( vm.teams, function( index, team ) {
                    if(team){
                        newArray.set(team.id, team);
                    }
                     
                  });
                  $.each( result.teams, function( index, team ) {
                    if(team){
                        newArray.set(team.id, team);  
                    }
                     
                  });
                
                 vm.teams = Array.from(newArray.values());
               }
               if (result.tournamentType === 'GSL_STYLE'){
                    TournamentBracket.groupsRoundRobin({id:  result.id}, onSuccessGslGroupsForTeams, onSaveErrorsStandings); 
                }
            }

        }


    }
})();
