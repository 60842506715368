(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('TournamentRoundController',TournamentRoundController);

    TournamentRoundController.$inject = ['$uibModalInstance', 'entity', 'Tournament','RoundByTournamentService','RoundSingleByTournamentService','$scope','RoundRobinByTournamentService','TournamentBracket'];

    function TournamentRoundController($uibModalInstance, entity, Tournament,RoundByTournamentService, RoundSingleByTournamentService,$scope,RoundRobinByTournamentService,TournamentBracket) {
        var vm = this;

        vm.tournament = entity;
        vm.clear = clear;
        vm.confirmRoundGen = confirmRoundGen;
        
        $scope.divLoading = 'none';
        $scope.deBracketType="0";
        $scope.gslGroupSelected="0";
        $scope.totalfreematches=0;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.standingStatus = ["OK"];
    	if (vm.tournament.tournamentType == 'SWISS_STYLE'){
    		TournamentBracket.checkMissingStandings({id:  vm.tournament.id}, onSaveSuccessStandings, onSaveErrorsStandings); 
    	}
    	
    	
        
        function confirmRoundGen (id) {
          /*  Tournament.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });*/
        	$scope.divLoading = 'block';
        	if (vm.tournament.tournamentType == 'SWISS_STYLE'){
        		RoundByTournamentService.generateRound({id: id},onSaveSuccess,onSaveError);
        	}
        	else if (vm.tournament.tournamentType == 'SINGLE_ELIMINATION' || vm.tournament.tournamentType == 'EMLS_SINGLE_ELIMINATION'){
        		RoundSingleByTournamentService.generateRound({id: id},onSaveSuccess,onSaveError);        		
        	}else if (vm.tournament.tournamentType == 'ROUND_ROBIN'){
        		RoundRobinByTournamentService.generateAllRoundRobin({id: id},onSaveSuccess,onSaveError);    
        	}else if (vm.tournament.tournamentType == 'CUSTOM_ROUND_ROBIN' || vm.tournament.tournamentType == 'EMLS_ROUND_ROBIN'){
        		RoundRobinByTournamentService.generateAllCustomRoundRobin({id: id},onSaveSuccess,onSaveError);    
        	}else if (vm.tournament.tournamentType == 'CUSTOM_SINGLE_ELIMINATION'|| vm.tournament.tournamentType == 'EMLS_SINGLE_ELIMINATION_FREE'){
        		RoundRobinByTournamentService.generateCustomSingleElimination({id: id},onSaveSuccess,onSaveError);    
        	}else if (vm.tournament.tournamentType == 'DOUBLE_ELIMINATION' ){
        		TournamentBracket.createRoundDoubleElim({id: id, bracketType:$scope.deBracketType},onSaveSuccess,onSaveError);
        		
        	}else if (vm.tournament.tournamentType === 'GSL_STYLE' ){
        		TournamentBracket.createRoundGslStyle({id: id, gslGroup:0},onSaveSuccess,onSaveError);
        		
            }else if (vm.tournament.tournamentType === 'FREE_ROUNDS' ){
        		TournamentBracket.createFreeRoundStyle({id: id, totalfreematches:$scope.totalfreematches},onSaveSuccess,onSaveError);
        		
            }else if (vm.tournament.tournamentType == 'DOUBLE_ELIMINATION_FREE' ){
        		TournamentBracket.createFreeRoundDE({id: id},onSaveSuccess,onSaveError);        		
            }else if (vm.tournament.tournamentType == 'LEADERBOARD' ){
        		TournamentBracket.createLeaderboardRound({id: id},onSaveSuccess,onSaveError);        		
        	}else if (vm.tournament.tournamentType == 'VALORANT_CUSTOM_ROUND_ROBIN' ){
        		TournamentBracket.createValorantRoundRobin({id: id},onSaveSuccess,onSaveError);        		
        	}else if (vm.tournament.tournamentType == 'VALORANT_DOUBLE_ELIMINATION_FREE' ){
        		TournamentBracket.createValorantFreeRoundDE({id: id},onSaveSuccess,onSaveError);        		
          }
          else if (vm.tournament.tournamentType === 'BEST_OF_FREE' ){
              TournamentBracket.createBestOfFreeRoundStyle({id: id, totalfreematches:$scope.totalfreematches},onSaveSuccess,onSaveError);            
          }
          else if (vm.tournament.tournamentType === 'BEST_OF_DE' ){
            TournamentBracket.createBestOfDoubleElimRoundStyle({id: id},onSaveSuccess,onSaveError);
        }
            
        }
        
        function onSaveSuccess (result) {
           //console.log(result);
           $uibModalInstance.close(true);
           $scope.divLoading = 'none';
        }
        
        function onSaveError () {
        	 $scope.divLoading = 'none';
        }
        
         function onSaveSuccessStandings (result) {
          //console.log(result);
          $scope.standingStatus = result;
         }
         
         function onSaveErrorsStandings () {
         	
         }
         function onSuccessGslGroups (result) {
             //console.log(JSON.stringify(result[0]));
             vm.gslGroups = result;
         }
         
         $scope.RadioChange = function (s) {
             $scope.gslGroupSelected = s;
         };
            
       
    }
})();
