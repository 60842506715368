(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('MatchsDetailController', MatchsDetailController);

    MatchsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Matchs', 'Team', 'Tournament', 'Score', 'Round','$window','CurrentNavigationApp'];

    function MatchsDetailController($scope, $rootScope, $stateParams, previousState, entity, Matchs, Team, Tournament, Score, Round,$window,CurrentNavigationApp) {
        var vm = this;

        vm.matchs = entity;
        vm.previousState = previousState.name;

        CurrentNavigationApp.addMatch(vm.matchs.id,vm.matchs.name);
        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();
        
        var unsubscribe = $rootScope.$on('ldtpApp:matchsUpdate', function(event, result) {
            vm.matchs = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        $scope.goBack = function(){        	
        	//$window.location.href =  $scope.backUrl;
        	$window.history.back();
        }
    }
})();
