(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('TournamentSeedDetailController', TournamentSeedDetailController);

    TournamentSeedDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Tournament', 'Organization', 'Team', 'Player', 'Round','$window','CurrentNavigationApp','StandingsByTournamentSearch','TournamentBracket','Seed'];

    function TournamentSeedDetailController($scope, $rootScope, $stateParams, previousState, entity, Tournament, Organization, Team, Player, Round,$window,CurrentNavigationApp,StandingsByTournamentSearch,TournamentBracket,Seed) {
        var vm = this;

        vm.tournament = entity;
        vm.previousState = previousState.name;
        
        CurrentNavigationApp.addTournament(vm.tournament.id,vm.tournament.name, vm.tournament.playersOrTeams, vm.tournament.tournamentType);
        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();
        
      	
        var unsubscribe = $rootScope.$on('ldtpApp:tournamentUpdate', function(event, result) {
            vm.tournament = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        vm.isSaving = false;
        
        //Define row based in the participant number
        var players = vm.tournament.totalParticipants;
        if (players != null && players > 0){
        	var rows = players ;/// 2;
        	$scope.totalRows = rows;
        	$scope.getNumber = function(num) {
        	    return new Array(num);   
        	}
        	$scope.customMatch = [];
        	//$scope.customSeed = [];
        	
        	//GEt the Seed from the entities from torunament
        	/*for (var i =0; i < $scope.totalRows;i++){
        		$scope.customSeed[i] = {seed:i+1, player:null};
        	}
        	*/
        }

        function clear () {
          
        }
        
        $scope.sorterFunc = function(seed){
            return parseInt(seed.seed);
        };

   
        vm.save = function () {          
            
            
            if (vm.tournament.id !== null) {  
            	/*console.log( $scope.customMatch);
            	var sendToSave = validateMatchs();
            	console.log(sendToSave);
            	if (sendToSave){
            		 vm.isSaving = true;
            		TournamentBracket.initialRoundFromSeed({id:  vm.tournament.id},$scope.customMatch,onSaveSuccess, onSaveError);
            	} 
            	*/
            	TournamentBracket.initialRoundFromSeed({id:  vm.tournament.id}, vm.tournament.seeds,onSaveSuccess, onSaveError);
            } 
           
           
        }
        
        function validateMatchs(){
        	var matchs = $scope.customMatch;
        	
        	var flagPass = false;
        	if (matchs != null && matchs.length > 0){
        		var seeds = vm.tournament.seeds;
        		for (var indexPlay = 0; indexPlay < seeds.length; indexPlay++){
        			 var idPlay = seeds[indexPlay].seed;
        			 var countUsed = 0;
        			 var msg = "";
        			 for (var indexMatch= 0; indexMatch < matchs.length; indexMatch++){
        				 var matchPlayOneId =  matchs[indexMatch].seedPlayerOne.seed;
        				 var matchPlayTwoId =  matchs[indexMatch].seedPlayerTwo.seed;
        				 
        				 if (idPlay == matchPlayOneId){
        					 countUsed++;
        					 msg += " Match " + (indexMatch + 1);
        				 }
        				 if (idPlay == matchPlayTwoId){
        					 countUsed++;
        					 msg += " Match " + (indexMatch + 1);
        				 }
        			 }
        			 if (countUsed > 1){
        				 alert ("The Seed " + seeds[indexPlay].seed + " was used in " + countUsed + " times"  +"[" + msg + "]");
        				
        				 return flagPass;
        			 }
        		}
        		flagPass = true;
        	
        	}
        	return flagPass;
        }

        function onSaveSuccess (result) {
           // $scope.$emit('ldtpApp:tournamentUpdate', result);
           // $uibModalInstance.close(result);
            vm.isSaving = false;
            $window.location= "/#/tournament/tournament/" + result.id;  
            $window.location.reload();
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
 
  }
})();
