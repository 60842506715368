(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('GameEventDetailController', GameEventDetailController);

    GameEventDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'GameEvent'];

    function GameEventDetailController($scope, $rootScope, $stateParams, previousState, entity, GameEvent) {
        var vm = this;

        vm.gameEvent = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('ldtpApp:gameEventUpdate', function(event, result) {
            vm.gameEvent = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
