(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('RoundScanMatchesController',RoundScanMatchesController);

    RoundScanMatchesController.$inject = ['$uibModalInstance', 'entity', 'Round'];

    function RoundScanMatchesController($uibModalInstance, entity, Round) {
        var vm = this;

        vm.matches = entity;
        vm.clear = clear;
        vm.confirmScan = confirmScan;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmScan () {
         /*   Round.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
           */
        	 $uibModalInstance.close(true);
        }
    }
})();
