(function () {
    'use strict';
    angular
        .module('ldtpApp')
        .factory('RoundCommonUtils', RoundCommonUtils);

    RoundCommonUtils.$inject = ['$resource', 'DateUtils'];

    function RoundCommonUtils($resource, DateUtils) {
        var resourceUrl = 'api/rounds/:command/:id';

        return $resource(resourceUrl, {
            command: '@command'

        }, {

            'getMatchesStatusFromRound': {
                method: 'POST',
                params: {
                    command: 'matches-status',
                    id: '@id'
                },
                isArray: true
            },
            'publishRoundInPublic': {
                method: 'POST',
                params: {
                    command: 'publish-round-public',
                    id: '@id',
                    publicFlag: '@publicFlag'
                },
                isArray: true
            },
            'updateRoundName':{
            	method: 'POST',
                params: {
                    command: 'update-round-name',
                    id: '@id',
                    newRoundName: '@newRoundName'
                },
                isArray: true
            	
            },
            'updateGameProgressInRound':{
            	method: 'POST',
                params: {
                    command: 'update-game-progress',
                    id: '@id',
                    newGameProgress: '@newGameProgress'
                },
                isArray: true            	
            }
        });
    }
})();
