(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('TournamentDeleteController',TournamentDeleteController);

    TournamentDeleteController.$inject = ['$scope','$uibModalInstance', 'entity', 'Tournament'];

    function TournamentDeleteController($scope,$uibModalInstance, entity, Tournament) {
        var vm = this;

        vm.tournament = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        $scope.divLoading = 'none';

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
        	 $scope.divLoading = 'block';
            Tournament.delete({id: id},
                function () {
            	 $scope.divLoading = 'none';
                    $uibModalInstance.close(true);
                },
                
                function () {
               	 $scope.divLoading = 'none';                     
                 });
        }
    }
})();
