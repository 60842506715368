(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .factory('RoundByTournamentService', RoundByTournamentService);

    RoundByTournamentService.$inject = ['$resource'];

    function RoundByTournamentService($resource) {
        var resourceUrl =  'api/tournaments/round/:id';

        return $resource(resourceUrl, {id:'@id'}, {
            'query': { method: 'GET', isArray: true},
            'generateRound': { method:'POST'
            	, params: {                     
                    id: '@id'
                }
            }
        });
    }
})();
