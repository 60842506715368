(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('MatchsDeleteController',MatchsDeleteController);

    MatchsDeleteController.$inject = ['$uibModalInstance', 'entity', 'Matchs'];

    function MatchsDeleteController($uibModalInstance, entity, Matchs) {
        var vm = this;

        vm.matchs = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Matchs.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
