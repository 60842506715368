(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .factory('RoundSingleByTournamentService', RoundSingleByTournamentService);

    RoundSingleByTournamentService.$inject = ['$resource'];

    function RoundSingleByTournamentService($resource) {
        var resourceUrl =  'api/tournaments/round/:id/single';

        return $resource(resourceUrl, {id:'@id'}, {
           
            'generateRound': { method:'POST'
            	, params: {                     
                    id: '@id'
                }
            }
        });
    }
})();
