(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('TournamentDeckAssignmentController', TournamentDeckAssignmentController);

    TournamentDeckAssignmentController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Tournament', 'Organization', 'Team', 'Player', 'Round','$window','CurrentNavigationApp','StandingsByTournamentSearch','TournamentBracket','Seed','TournamentDeckService'];

    function TournamentDeckAssignmentController($scope, $rootScope, $stateParams, previousState, entity, Tournament, Organization, Team, Player, Round,$window,CurrentNavigationApp,StandingsByTournamentSearch,TournamentBracket,Seed,TournamentDeckService) {
        var vm = this;

        vm.tournament = entity;
        vm.previousState = previousState.name;

        $scope.magicDeckList = [];
        $scope.divLoading = 'none';
        TournamentBracket.getPlayerDecksFromTournament({id:  vm.tournament.id}, onGetDeckSuccess, onSaveError);

      
        
        CurrentNavigationApp.addTournament(vm.tournament.id,vm.tournament.name, vm.tournament.playersOrTeams, vm.tournament.tournamentType);
        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();
        
      	
        var unsubscribe = $rootScope.$on('ldtpApp:tournamentUpdate', function(event, result) {
            vm.tournament = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        vm.isSaving = false;
        
        //Define row based in the participant number
        var players = vm.tournament.totalParticipants;
        if (players !== null && players > 0){        
            $scope.totalRows = players;    
                    
            $scope.getNumber = function(num) {
        	    return new Array(num);   
            };
            
        	
        }

        function clear () {
          
        }
        
        $scope.sorterFunc = function(seed){
            return parseInt(seed.seed);
        };
   
        vm.save = function () {    
            if (vm.tournament.id !== null) {                              
                TournamentBracket.saveDeckFromTournament({id:  vm.tournament.id},  $scope.magicDeckList,onSaveSuccess, onSaveError);
            } 
           
        }
        

        function onSaveSuccess (result) {
           // $scope.$emit('ldtpApp:tournamentUpdate', result);
           // $uibModalInstance.close(result);
            vm.isSaving = false;
            $window.location= "/#/tournament/tournament/" +vm.tournament.id;  
            $window.location.reload();
        }

        function onGetDeckSuccess (result) {
            if (result !== null && result.length > 0){
                $scope.magicDeckList = result;
            }else {
                if (vm.tournament.players !== null){
                    vm.tournament.players.forEach(function (element) {
                        var item = {player: element, link: null, name:null};
                        $scope.magicDeckList.push(item);
        
                    });
                }
            }
           
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        $scope.loadLastDeckList = function (deckId) {  
            $scope.divLoading = 'block';
            TournamentDeckService.loadLastDeckCardList({deckId : deckId}, onLoadLastDecksSuccess);
        }; 
        
        function onLoadLastDecksSuccess (result) {
            $scope.divLoading = 'none';
        }
 
    }
})();
