(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('GroupRoundRobinDetailController', GroupRoundRobinDetailController);

    GroupRoundRobinDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'GroupRoundRobin', 'Tournament', 'Player'];

    function GroupRoundRobinDetailController($scope, $rootScope, $stateParams, previousState, entity, GroupRoundRobin, Tournament, Player) {
        var vm = this;

        vm.groupRoundRobin = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('ldtpApp:groupRoundRobinUpdate', function(event, result) {
            vm.groupRoundRobin = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
