(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('MediaResourceDetailController', MediaResourceDetailController);

    MediaResourceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'MediaResource', 'Score','$window'];

    function MediaResourceDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, MediaResource, Score,$window) {
        var vm = this;

        vm.mediaResource = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('ldtpApp:mediaResourceUpdate', function(event, result) {
            vm.mediaResource = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        $scope.goBack = function(){        	   	
        	$window.history.back();
        }
    }
})();
