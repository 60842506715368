(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .factory('ScoreBoardSearch', ScoreBoardSearch);

    ScoreBoardSearch.$inject = ['$resource'];

    function ScoreBoardSearch($resource) {
        var resourceUrl =  'api/_search/score-boards/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
