(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('TournamentTieBreakingController',TournamentTieBreakingController);

    TournamentTieBreakingController.$inject = ['$uibModalInstance', 'entity', 'Tournament','$scope','TournamentBracket'];

    function TournamentTieBreakingController($uibModalInstance, entity, Tournament,$scope,TournamentBracket) {
        var vm = this;

        vm.tournament = entity;
        vm.clear = clear;
        vm.confirmRoundGen = confirmRoundGen;
        
        $scope.divLoading = 'none';
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmRoundGen (id) {
         
        	$scope.divLoading = 'block';
        	TournamentBracket.calculateTieBreaking({id: vm.tournament.id}, onSaveSuccess, onSaveError);
        	
        }
        
        function onSaveSuccess (result) {
          
           $uibModalInstance.close(true);
           $scope.divLoading = 'none';
        }
        
        function onSaveError () {
        	 $scope.divLoading = 'none';
        }
    }
})();
