(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('tournament', {
            parent: 'entity',
            url: '/tournament',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.tournament.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tournament/tournaments.html',
                    controller: 'TournamentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tournament');
                    $translatePartialLoader.addPart('platform');
                    $translatePartialLoader.addPart('tournamentType');
                    $translatePartialLoader.addPart('playersOrTeams');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('tournament-detail', {
            parent: 'tournament',
            url: '/tournament/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.tournament.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tournament/tournament-detail.html',
                    controller: 'TournamentDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tournament');
                    $translatePartialLoader.addPart('platform');
                    $translatePartialLoader.addPart('tournamentType');
                    $translatePartialLoader.addPart('playersOrTeams');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Tournament', 'TournamentLigthDetail', function($stateParams, Tournament, TournamentLigthDetail) {
                    //return Tournament.get({id : $stateParams.id}).$promise;
                	return TournamentLigthDetail.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'tournament',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('tournament-detail.edit', {
            parent: 'tournament-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tournament/tournament-dialog.html',
                    controller: 'TournamentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Tournament', function(Tournament) {
                            return Tournament.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tournament.new', {
            parent: 'tournament',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tournament/tournament-dialog.html',
                    controller: 'TournamentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                gameName: null,
                                platform: null,
                                tournamentType: null,
                                tournamentDate: null,
                                playersOrTeams: 'PLAYERS',
                                totalParticipants: null,
                                blockBracket: null,
                                jsonBracket: null,
                                matchGamesInSwiss: null,
                                roundsEliminated: null,
                                allowTiesRoundRobin : null,
                                groupsNumberRoundRobin : null,
                                groupsSizeRoundRobin : null,
                                matchesForRound: null,
                                gamesForMatch: null,
                                penaltyKickSupport: null,
                                showBannerPlayer: null,
                                showCountryPlayer: null,
                                showPhotoPlayer: null,
                                matchesWonQualification : null,
                                tieBreakerType: 'MB',
                                calcAutoSe: false,
                                bestOfGames:null,
                                matchesByRound: 1,
                                tournamentStatus: null,
                                tournamentZone: null,
                                battleRoyal:false,
                                brPointsPerKill:null,
                                brNumBestRounds:null,
                                brRowsToFillPositions:null,
                                mapsInRound:null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('tournament', null, { reload: 'tournament' });
                }, function() {
                    $state.go('tournament');
                });
            }]
        })
        .state('tournament.edit', {
            parent: 'tournament',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tournament/tournament-dialog.html',
                    controller: 'TournamentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Tournament', function(Tournament) {
                            return Tournament.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tournament', null, { reload: 'tournament' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tournament.delete', {
            parent: 'tournament',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tournament/tournament-delete-dialog.html',
                    controller: 'TournamentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Tournament', function(Tournament) {
                            return Tournament.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tournament', null, { reload: 'tournament' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tournament-seed', {
            parent: 'tournament',
            url: '/tournament/seed/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.tournament.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tournament/tournament-seed-detail-new-model.html',
                    controller: 'TournamentSeedDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tournament');
                    $translatePartialLoader.addPart('platform');
                    $translatePartialLoader.addPart('tournamentType');
                    $translatePartialLoader.addPart('playersOrTeams');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Tournament', function($stateParams, Tournament) {
                    return Tournament.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'tournament',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

        .state('tournament-magic-deck', {
            parent: 'tournament',
            url: '/tournament/deck/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.tournament.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tournament/tournament-deck-player-assignment.html',
                    controller: 'TournamentDeckAssignmentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tournament');
                    $translatePartialLoader.addPart('platform');
                    $translatePartialLoader.addPart('tournamentType');
                    $translatePartialLoader.addPart('playersOrTeams');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Tournament', function($stateParams, Tournament) {
                    return Tournament.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'tournament',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

        .state('tournament-round-events-schedule', {
            parent: 'tournament',
            url: '/tournament/round-events/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.tournament.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tournament/tournament-round-event-schedule.html',
                    controller: 'TournamentRoundEventScheduleController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tournament');
                    $translatePartialLoader.addPart('platform');
                    $translatePartialLoader.addPart('tournamentType');
                    $translatePartialLoader.addPart('playersOrTeams');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Tournament', function($stateParams, Tournament) {
                    return Tournament.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'tournament',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('tournament-round-positions-br', {
            parent: 'tournament',
            url: '/tournament/round-positions-br/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.tournament.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tournament/tournament-round-positions-br.html',
                    controller: 'TournamentRoundPositionsBattleRoyalController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tournament');
                    $translatePartialLoader.addPart('platform');
                    $translatePartialLoader.addPart('tournamentType');
                    $translatePartialLoader.addPart('playersOrTeams');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Tournament', function($stateParams, Tournament) {
                    return Tournament.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'tournament',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
         .state('tournament-initial-round', {
            parent: 'tournament',
            url: '/tournament/initial-round/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.tournament.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tournament/tournament-initial-round-detail.html',
                    controller: 'TournamentInitialRoundDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tournament');
                    $translatePartialLoader.addPart('platform');
                    $translatePartialLoader.addPart('tournamentType');
                    $translatePartialLoader.addPart('playersOrTeams');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Tournament', function($stateParams, Tournament) {
                    return Tournament.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'tournament',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
         .state('tournament-groups-round-robin', {
            parent: 'tournament',
            url: '/tournament/groups-round-robin/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.tournament.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tournament/tournament-round-robin-initial.html',
                    controller: 'TournamentRoundRobinInitialController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tournament');
                   /* $translatePartialLoader.addPart('platform');
                    $translatePartialLoader.addPart('tournamentType');
                    $translatePartialLoader.addPart('playersOrTeams'); */
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Tournament', 'CurrentNavigationApp','TournamentBracket', function($stateParams, Tournament,CurrentNavigationApp, TournamentBracket) {
                    //return Tournament.get({id : $stateParams.id}).$promise;
                			var navObj = CurrentNavigationApp.getCurrentNavigation();
                			console.log("Load Groups from Torunament" + navObj.Tournament_id);
                			return TournamentBracket.groupsRoundRobin({id:  navObj.Tournament_id}).$promise;
                			//return TournamentBracket.scanMatches({id:  navObj.Tournament_id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'tournament',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('tournament.round', {
            parent: 'tournament',
            url: '/{id}/round',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tournament/tournament-round-dialog.html',
                    controller: 'TournamentRoundController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Tournament', function(Tournament) {
                            return Tournament.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {                	
                    $state.go('tournament-detail', $stateParams, { reload: 'tournament' });
                }, function() {                	
                    //$state.go('^');
                	 $state.go('tournament-detail', $stateParams, { reload: 'tournament' });
                });
            }]
        })
        .state('tournament.deckcardlist', {
            parent: 'tournament',
            url: '/tournament/decklist/{deckId}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal','$log','CurrentNavigationApp', function($stateParams, $state, $uibModal,$log,CurrentNavigationApp) {
                var navObj = CurrentNavigationApp.getCurrentNavigation();
                $uibModal.open({
                    templateUrl: 'app/entities/tournament/tournament-deck-card-list.html',
                    controller: 'TournamentDeckCardListController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['TournamentDeckService', function(TournamentDeckService) {
                            $log.log("Getting INformation" ); 
                            return TournamentDeckService.getDeckCardList({deckId : $stateParams.deckId}).$promise;
                        }]
                    }
                }).result.then(function() { 
                    $log.log("One ",$stateParams );               	
                    $state.go('tournament-magic-deck', {id:  navObj.Tournament_id}, { reload: 'tournament' });
                }, function() { 
                    $log.log("Two ",$stateParams );           	   
                	 $state.go('tournament-magic-deck', {id:  navObj.Tournament_id}, { reload: 'tournament' });
                });
            }]
        }).state('tournament.calculateTieBreaking', {
            parent: 'tournament',
            url: '/{id}/tiebreaking',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal','TournamentLigthDetail', function($stateParams, $state, $uibModal, TournamentLigthDetail) {
                $uibModal.open({
                    templateUrl: 'app/entities/tournament/tournament-tie-breaking-calculate.html',
                    controller: 'TournamentTieBreakingController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Tournament','TournamentLigthDetail', function(Tournament,  TournamentLigthDetail) {
                            return TournamentLigthDetail.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {                	
                    $state.go('tournament-detail', $stateParams, { reload: 'tournament' });
                }, function() {           	   
                	 $state.go('tournament-detail', $stateParams, { reload: 'tournament' });
                });
            }]
        })
        ;
    }

})();
