(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('OrganizationDetailController', OrganizationDetailController);

    OrganizationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Organization', 'User','$window'];

    function OrganizationDetailController($scope, $rootScope, $stateParams, previousState, entity, Organization, User,$window) {
        var vm = this;

        vm.organization = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('ldtpApp:organizationUpdate', function(event, result) {
            vm.organization = result;
        });
        $scope.$on('$destroy', unsubscribe);
        

 		$scope.goBack = function(){      	
           	$window.history.back();
        }
    }
})();
