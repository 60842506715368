(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('ScoreDialogController', ScoreDialogController);

    ScoreDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Score', 'Matchs'];

    function ScoreDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Score, Matchs) {
        var vm = this;

        vm.score = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.matchs = Matchs.query();
        
        if (vm.score.name != null){
        	var scoreName = vm.score.name;
        	var indexStr = scoreName.search(' - Game');
        	
        	if (indexStr != -1){
        		 var players = scoreName.substring(0, indexStr).split(' vs ');
        		 if (players != null && players.length == 2 ){
        			 $scope.playerOneLbl = players[0];
        			 $scope.playerTwoLbl = players[1];
        		 }
        	}
        	
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.score.id !== null) {
                Score.update(vm.score, onSaveSuccess, onSaveError);
            } else {
                Score.save(vm.score, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ldtpApp:scoreUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.gameDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
