(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('PlayerDetailController', PlayerDetailController);

    PlayerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Player', 'Organization', 'Countries', 'Tournament', 'Team','$window','CurrentNavigationApp','PlayerHistoryByTournament','AlertService','PlayerScoreBoardByTournament','PlayerHistoryByPlayer'];

    function PlayerDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Player, Organization, Countries, Tournament, Team,$window,CurrentNavigationApp,PlayerHistoryByTournament,AlertService,PlayerScoreBoardByTournament,PlayerHistoryByPlayer) {
        var vm = this;

        vm.player = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        
        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();
        
        var navObj = CurrentNavigationApp.getCurrentNavigation();
        /*if (navObj != null && navObj.Tournament_id != null  ){
        	PlayerHistoryByTournament.query({tourId:navObj.Tournament_id,playerId:vm.player.id},onSuccess,onError);
        	function onSuccess(data) {
                   $scope.playerHistory = data;
        	}

        	function onError(error) {
        		AlertService.error(error.data.message);
        	}
        	
        	

        	PlayerScoreBoardByTournament.query({tourId:navObj.Tournament_id,playerId:vm.player.id},onSuccessBoard,onErrorBoard);
        	             function onSuccessBoard(data) {
        	             
        	                 $scope.playerScoreBoard = data;
        	             }

        	             function onErrorBoard(error) {
        	                 AlertService.error(error.data.message);
        	             }
          
        }*/
        	PlayerHistoryByPlayer.query({playerId:vm.player.id},onSuccessBoard,onErrorBoard);
            	function onSuccessBoard(data) {
            		
            		$scope.playerGlobalHistory = data;
            	}

            	function onErrorBoard(error) {
            		AlertService.error(error.data.message);
            	}
        


        var unsubscribe = $rootScope.$on('ldtpApp:playerUpdate', function(event, result) {
            vm.player = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        $scope.goBack = function(){      	
           	$window.history.back();
        }
    }
})();
