(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('TournamentRoundRobinInitialController', TournamentRoundRobinInitialController);

    TournamentRoundRobinInitialController.$inject = ['$q','$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Tournament', 'Organization', 'Team', 'Player', 'Round','$window','CurrentNavigationApp','GroupRoundRobin'];

    function TournamentRoundRobinInitialController($q,$scope, $rootScope, $stateParams, previousState, entity, Tournament, Organization, Team, Player, Round,$window,CurrentNavigationApp,GroupRoundRobin) {
        var vm = this;

        vm.groups = entity;
      
        
      
        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();
        
       
        vm.customAssigment=[];
		if (  $scope.currentNavigation != null && $scope.currentNavigation.Tournament_type =='PLAYERS' ){
			for (var groupIndex = 0; groupIndex < entity.length; groupIndex++){
				for (var indexPlayer= 0; indexPlayer < entity[groupIndex].players.length; indexPlayer++){
					var playerAssigmentItem = {player:entity[groupIndex].players[indexPlayer], group: entity[groupIndex]};
					vm.customAssigment.push(playerAssigmentItem);
				}
			}
		}else{
			for (var groupIndex = 0; groupIndex < entity.length; groupIndex++){
				for (var indexTeam= 0; indexTeam < entity[groupIndex].teams.length; indexTeam++){
					var teamAssigmentItem = {team:entity[groupIndex].teams[indexTeam], group: entity[groupIndex]};
					vm.customAssigment.push(teamAssigmentItem);
				}
			}
		}
        
      	
        console.log(vm.customAssigment);               
        vm.isSaving = false;
        
      
        function clear () {        
        }
        
       
 
        	$scope.refreshGroups = function() {
			if (  $scope.currentNavigation != null && $scope.currentNavigation.Tournament_type =='PLAYERS' ){
				for (var groupIndex = 0; groupIndex < entity.length; groupIndex++){
					entity[groupIndex].players = []; 
				}
				
				for (var groupIndex = 0; groupIndex < vm.customAssigment.length; groupIndex++){
					var customGroupAssign = vm.customAssigment[groupIndex];        		 
					var objEntity = entity.find(function(element) {
						 return element.id  === customGroupAssign.group.id;
					});
					objEntity.players.push(customGroupAssign.player);
				}
			}else {
				for (var groupIndex = 0; groupIndex < entity.length; groupIndex++){
					entity[groupIndex].teams = []; 
				}
				
				for (var groupIndex = 0; groupIndex < vm.customAssigment.length; groupIndex++){
					var customGroupAssign = vm.customAssigment[groupIndex];        		 
					var objEntity = entity.find(function(element) {
						 return element.id  === customGroupAssign.group.id;
					});
					objEntity.teams.push(customGroupAssign.team);
				}
			}
        	 
        }
   
        vm.save = function () {          	
        	 
        	 vm.isSaving = true;
	           /* $q.all(
	                $.map(entity, function (group) {	                    
	                    return GroupRoundRobin.update(group)
	                })).then(function (result) {
	                vm.isSaving = false;
	                onSaveSuccess()
	            });
            */
			
			GroupRoundRobin.bulkUpdate(entity, onSaveSuccess, onSaveError);
           
           
        }
        
        function validateMatchs(){
        	var matchs = $scope.customMatch;
        	
        	var flagPass = false;
        	if (matchs != null && matchs.length > 0){
        		var seeds = vm.tournament.seeds;
        		for (var indexPlay = 0; indexPlay < seeds.length; indexPlay++){
        			 var idPlay = seeds[indexPlay].seed;
        			 var countUsed = 0;
        			 var msg = "";
        			 for (var indexMatch= 0; indexMatch < matchs.length; indexMatch++){
        				 var matchPlayOneId =  matchs[indexMatch].seedPlayerOne.seed;
        				 var matchPlayTwoId =  matchs[indexMatch].seedPlayerTwo.seed;
        				 
        				 if (idPlay == matchPlayOneId){
        					 countUsed++;
        					 msg += " Match " + (indexMatch + 1);
        				 }
        				 if (idPlay == matchPlayTwoId){
        					 countUsed++;
        					 msg += " Match " + (indexMatch + 1);
        				 }
        			 }
        			 if (countUsed > 1){
        				 alert ("The Seed " + seeds[indexPlay].seed + " was used in " + countUsed + " times"  +"[" + msg + "]");
        				
        				 return flagPass;
        			 }
        		}
        		flagPass = true;
        	
        	}
        	return flagPass;
        }

        function onSaveSuccess (result) {  
        	var navObj = CurrentNavigationApp.getCurrentNavigation();			
            vm.isSaving = false;
            if (navObj != null && navObj.Tournament_id != null){ 
            	$window.location= "/#/tournament/tournament/" + navObj.Tournament_id ;  
            	$window.location.reload();
            }
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
 
  }
})();
