(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state','TournamentPublicFacing','AlertService'];

    function HomeController ($scope, Principal, LoginService, $state,TournamentPublicFacing,AlertService) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }
        
        vm.tournaments = [];
        /*TournamentPublicFacing.get({
           
        }, onSuccess, onError);
        */
        function onSuccess(data, headers) {          
        
        	 vm.tournaments = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }
    }
})();
