(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('PlayersLoaderDeleteController',PlayersLoaderDeleteController);

    PlayersLoaderDeleteController.$inject = ['$uibModalInstance', 'entity', 'PlayersLoader'];

    function PlayersLoaderDeleteController($uibModalInstance, entity, PlayersLoader) {
        var vm = this;

        vm.playersLoader = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PlayersLoader.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
