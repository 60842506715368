(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('TournamentInitialRoundDetailController', TournamentInitialRoundDetailController);

    TournamentInitialRoundDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Tournament', 'Organization', 'Team', 'Player', 'Round','$window','CurrentNavigationApp','StandingsByTournamentSearch','TournamentBracket','Seed'];

    function TournamentInitialRoundDetailController($scope, $rootScope, $stateParams, previousState, entity, Tournament, Organization, Team, Player, Round,$window,CurrentNavigationApp,StandingsByTournamentSearch,TournamentBracket,Seed) {
        var vm = this;

        vm.tournament = entity;
        vm.previousState = previousState.name;
        
        CurrentNavigationApp.addTournament(vm.tournament.id,vm.tournament.name, vm.tournament.playersOrTeams, vm.tournament.tournamentType);
        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();
        
      	
        var unsubscribe = $rootScope.$on('ldtpApp:tournamentUpdate', function(event, result) {
            vm.tournament = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        var players = vm.tournament.players;
        if (players != null && players.length > 0){
        	var rows = players.length / 2;
        	$scope.totalRows = rows;
        	$scope.getNumber = function(num) {
        	    return new Array(num);   
        	}
        	$scope.customMatch = [];
        }
        console.log("TOTal" + $scope.totalRows);

        function clear () {
         
        }
        
        function validateMatchs(){
        	var matchs = $scope.customMatch;
        	
        	var flagPass = false;
        	if (matchs != null && matchs.length > 0){
        		var players = vm.tournament.players;
        		for (var indexPlay = 0; indexPlay < players.length; indexPlay++){
        			 var idPlay = players[indexPlay].id;
        			 var countUsed = 0;
        			 var msg = "";
        			 for (var indexMatch= 0; indexMatch < matchs.length; indexMatch++){
        				 var matchPlayOneId =  matchs[indexMatch].playerOne.id;
        				 var matchPlayTwoId =  matchs[indexMatch].playerTwo.id;
        				 
        				 if (idPlay == matchPlayOneId){
        					 countUsed++;
        					 msg += " Match " + (indexMatch + 1);
        				 }
        				 if (idPlay == matchPlayTwoId){
        					 countUsed++;
        					 msg += " Match " + (indexMatch + 1);
        				 }
        			 }
        			 if (countUsed > 1){
        				 alert ("The Gamer " + players[indexPlay].gamerTag + " was used in " + countUsed + " times"  +"[" + msg + "]");
        				
        				 return flagPass;
        			 }
        		}
        		flagPass = true;
        	
        	}
        	return flagPass;
        }

        vm.save = function () {
           
            
           
            if (vm.tournament.id !== null) {               
            	var sendToSave = validateMatchs();
            	if (sendToSave){
            		 vm.isSaving = true;
            		TournamentBracket.initialRound({id:  vm.tournament.id},$scope.customMatch,onSaveSuccess, onSaveError);
            	} 
            } 
           
           
        }

        function onSaveSuccess (result) {      
            vm.isSaving = false;            
            $window.location= "/#/tournament/tournament/" + result.id;  
            $window.location.reload();
            console.log(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
 
  }
})();
