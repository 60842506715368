(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('MediaResourceDeleteController',MediaResourceDeleteController);

    MediaResourceDeleteController.$inject = ['$uibModalInstance', 'entity', 'MediaResource'];

    function MediaResourceDeleteController($uibModalInstance, entity, MediaResource) {
        var vm = this;

        vm.mediaResource = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MediaResource.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
