(function() {
    'use strict';
    angular
        .module('ldtpApp')
        .factory('TournamentBracket', TournamentBracket);

    TournamentBracket.$inject = ['$resource', 'DateUtils'];

    function TournamentBracket ($resource, DateUtils) {
        var resourceUrl =  'api/tournaments/:command/:id';

        return $resource(resourceUrl, {
        	command: '@command'

        }, {
            'random': {method:'POST', params: {command: 'random', id: '@id'}}
            ,'block': {method:'POST', params: {command: 'block', id: '@id'}}
            ,'saveBracket': {method:'POST', params: {command: 'bracket', id: '@id'}}
            ,'seed': {method:'POST', params: {command: 'seed', id: '@id'}}
            ,'initialRound': {method:'POST', params: {command: 'initial-round', id: '@id'}}
            ,'initialRoundFromSeed': {method:'POST', params: {command: 'initial-round-from-seed', id: '@id'}}
            ,'scanMatches': {method:'POST', params: {command: 'scan-matches', id: '@id'},isArray: true}
            ,'recalculateStandings': {method:'POST', params: {command: 'recalculate-standings', id: '@id'}}
            ,'groupsRoundRobin': {method:'POST', params: {command: 'groups-round-robin', id: '@id'},isArray: true}
            ,'groupsStandingsRoundRobin': {method:'POST', params: {command: 'groups-standing-round-robin', id: '@id'},isArray: true}
            ,'calculateTieBreaking': {method:'POST', params: {command: 'calculate-tie-breaking', id: '@id'}}
            ,'checkMissingStandings': {method:'POST', params: {command: 'check-missing-standings', id: '@id'},isArray: true}
            ,'createRoundDoubleElim': {method:'POST', params: {command: 'create-round-double-elim', id: '@id',bracketType:'@bracketType'}}
            ,'createRoundGslStyle': {method:'POST', params: {command: 'create-round-gsl-style', id: '@id',gslGroup:'@gslGroup'}}
            ,'updateGroupNameRoundRobin': {method:'POST', params: {command: 'update-group-name-rr', id: '@id',groupName:'@groupName'}}
            ,'saveDeckFromTournament': {method:'POST', params: {command: 'decks-assignment', id: '@id'}}
            ,'getPlayerDecksFromTournament': {method:'POST', params: {command: 'players-decks', id: '@id'},isArray: true}
            ,'saveRoundEventFromTournament': {method:'POST', params: {command: 'round-events-schedule', id: '@id'}}
            ,'getRoundEventFromTournament': {method:'GET', params: {command: 'round-events-schedule', id: '@id'},isArray: true}
            ,'createFreeRoundStyle': {method:'POST', params: {command: 'create-free-round-style', id: '@id',totalfreematches:'@totalfreematches'}}
            ,'createFreeRoundDE': {method:'POST', params: {command: 'create-free-round-de', id: '@id'}}
            ,'getCompleteInfoRoundEventFromTournament': {method:'GET', params: {command: 'complete-info-leaderboard', id: '@id'},isArray: true}
            ,'createLeaderboardRound': {method:'POST', params: {command: 'create-leaderboard-round', id: '@id'}}
            ,'getRoundPositionsBattleRoyalFromTournament': {method:'GET', params: {command: 'round-position-br', id: '@id'},isArray: true}
            ,'saveRoundPositionsBattleRoyalFromTournament': {method:'POST', params: {command: 'round-position-br', id: '@id'}}
            ,'getStandingBRBasedInPublicFacing': {method:'GET', params: {command: 'br-standings-tko-pf', id: '@id'}}
            ,'createValorantRoundRobin': {method:'POST', params: {command: 'create-valorant-round-robin', id: '@id'}}
            ,'createValorantFreeRoundDE': {method:'POST', params: {command: 'create-valorant-free-round-de', id: '@id'}}
            ,'createBestOfFreeRoundStyle': {method:'POST', params: {command: 'create-best-of-free-round-style', id: '@id',totalfreematches:'@totalfreematches'}}
            ,'createBestOfDoubleElimRoundStyle': {method:'POST', params: {command: 'create-best-double-de-round', id: '@id'}}
            ,'saveBulkScoreBestof': {method:'POST', params: {command: 'bulk-score-best-of', id: '@id',scores:'@scores'}}
        });
    }
})();