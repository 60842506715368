(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('ScoreDetailController', ScoreDetailController);

    ScoreDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Score', 'Matchs','$window','CurrentNavigationApp'];

    function ScoreDetailController($scope, $rootScope, $stateParams, previousState, entity, Score, Matchs,$window,CurrentNavigationApp) {
        var vm = this;

        vm.score = entity;
        vm.previousState = previousState.name;
        
        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();

        var unsubscribe = $rootScope.$on('ldtpApp:scoreUpdate', function(event, result) {
            vm.score = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        $scope.goBack = function(){      	
           	$window.history.back();
        }
    }
})();
