(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .factory('PlayerByOrganizationSearch', PlayerByOrganizationSearch);

    PlayerByOrganizationSearch.$inject = ['$resource'];

    function PlayerByOrganizationSearch($resource) {
        var resourceUrl =  'api/players/organization/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
