(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('SeedDialogController', SeedDialogController);

    SeedDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Seed', 'Player', 'Tournament'];

    function SeedDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Seed, Player, Tournament) {
        var vm = this;

        vm.seed = entity;
        vm.clear = clear;
        vm.save = save;
        vm.players = Player.query({filter: 'seed-is-null'});
        $q.all([vm.seed.$promise, vm.players.$promise]).then(function() {
            if (!vm.seed.player || !vm.seed.player.id) {
                return $q.reject();
            }
            return Player.get({id : vm.seed.player.id}).$promise;
        }).then(function(player) {
            vm.players.push(player);
        });
        vm.tournaments = Tournament.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.seed.id !== null) {
                Seed.update(vm.seed, onSaveSuccess, onSaveError);
            } else {
                Seed.save(vm.seed, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ldtpApp:seedUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
