(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('ScoreBoardController', ScoreBoardController);

    ScoreBoardController.$inject = ['ScoreBoard', 'ScoreBoardSearch'];

    function ScoreBoardController(ScoreBoard, ScoreBoardSearch) {

        var vm = this;

        vm.scoreBoards = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            ScoreBoard.query(function(result) {
                vm.scoreBoards = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            ScoreBoardSearch.query({query: vm.searchQuery}, function(result) {
                vm.scoreBoards = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
