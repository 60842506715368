(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('RoundDetailController', RoundDetailController);

    RoundDetailController.$inject = ['$q','$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Round', 'Tournament', 'Matchs','$window','CurrentNavigationApp','Score','$uibModal','$state','RoundCommonUtils','MathCommonUtils'];

    function RoundDetailController($q,$scope, $rootScope, $stateParams, previousState, entity, Round, Tournament, Matchs,$window,CurrentNavigationApp,Score,$uibModal,$state,RoundCommonUtils,MathCommonUtils) {
        var vm = this;

        vm.round = entity;
        vm.previousState = previousState.name;

        CurrentNavigationApp.addRound(vm.round.id,vm.round.name);
        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();

        var unsubscribe = $rootScope.$on('ldtpApp:roundUpdate', function(event, result) {
            vm.round = result;
        });
        $scope.$on('$destroy', unsubscribe);

        $scope.goBack = function(){
           	$window.history.back();
        };

        // Init search filter from rootScope
        $scope.searchTextGamerSeat = $rootScope.searchTextGamerSeat;

        // Watch changes to the search filter and store it to the scope
        $scope.$watch('searchTextGamerSeat', function(newValue, oldValue) {
            $rootScope.searchTextGamerSeat = newValue;
        });

        function validateSeat(seat) {
            if (!seat) {
                seat = 'No Seat';
            }
            return seat;
        }
        
        $scope.matchStatusRound = {};
        RoundCommonUtils.getMatchesStatusFromRound({id:vm.round.id},onSuccessMatchesStatus);

        // Make Sure all entities have a seat
        entity.matches.forEach(function (element) {
            if (element.playerLoser) {
                element.playerLoser.seat = validateSeat(element.playerLoser.seat);
            }
            if (element.playerWinner) {
                element.playerWinner.seat = validateSeat(element.playerWinner.seat);
            }
            if (element.playerOne) {
                element.playerOne.seat = validateSeat(element.playerOne.seat);
            }
            if (element.playerTwo) {
                element.playerTwo.seat = validateSeat(element.playerTwo.seat);
            }
        });

        var maxRequest = 0;
        var currentRequest = 0;

        $scope.updateScore = function (scores,  match){
            //console.log("Current Value" + JSON.stringify(scores));
            $scope.lastMatchUpdated = match.id;   
               $("#spinner-" +  $scope.lastMatchUpdated).show();       
        	Matchs.update(match);
        	Score.bulkUpdate(scores, onSaveSuccess, onSaveError);        
        };
        
        $scope.updateValorantScore = function (score, match){
            //console.log("Current Value" + JSON.stringify(scores));
            $scope.lastMatchUpdated = match.id;   
            $scope.lastValorantScoreUpdated = score.id;   
               $("#spinner-" +  $scope.lastValorantScoreUpdated).show();       
            var scoresData = [];
            scoresData.push(score);
        	Score.bulkUpdate(scoresData, onSaveSuccessValorantScore, onSaveError);        
        };

        function onSaveSuccessValorantScore (result) {
            if(result && $scope.lastMatchUpdated){
                Matchs.get({id:$scope.lastMatchUpdated}, onGetMatchUpdatedSuccessForValorant, onSaveError);
                //$("#spinner-" +  $scope.lastValorantScoreUpdated).hide();
            }
        }

        function onGetMatchUpdatedSuccessForValorant (result) {            
            if (vm && vm.round && vm.round.matches){
                $.each( vm.round.matches, function( index, match ) {
	            	if ( match.id === result.id) {
                        vm.round.matches[index] = result;
                        RoundCommonUtils.getMatchesStatusFromRound({id:vm.round.id},onSuccessMatchesStatus);            
                        $("#spinner-" +  $scope.lastValorantScoreUpdated).hide();
                        return;
	               }
	            	;
	            });
                
            }
        }
        
        $scope.saveScoreOptimized = function(scoresList){
        	//vm.isSaving = true;
            $q.all(
                $.map(scoresList, function (score) {	                    
                    return Score.update(score)
                })).then(function (result) {
              
                onSaveSuccess();
            });
        
        };

        $('#round-table').stickyTableHeaders();

        function onSaveSuccess (result) {
            if(result && $scope.lastMatchUpdated){
                Matchs.get({id:$scope.lastMatchUpdated}, onGetMatchUpdatedSuccess, onSaveError);
            }
        }
        function onGetMatchUpdatedSuccess (result) {            
            if (vm && vm.round && vm.round.matches){
                $.each( vm.round.matches, function( index, match ) {
	            	if ( match.id === result.id) {
                        vm.round.matches[index] = result;
                        RoundCommonUtils.getMatchesStatusFromRound({id:vm.round.id},onSuccessMatchesStatus);            
                        $("#spinner-" +  $scope.lastMatchUpdated).hide();
                        return;
	               }
	            	;
	            });
                
            }
        }

        function onSaveError () {
        	console.log("Any Issue ");
        }
        
        function onSuccessMatchesStatus (result) {
        	/*console.log(result[0]);
        	var obj = result[0];
        	console.log("Pendings" + obj["0"]);
        	console.log("Completed" + obj["1"]);
        	console.log("Completed" + obj["2"]);
        	console.log("Saving " + JSON.stringify(result));
        	*/
        	$scope.matchStatusRound = result[0];
        	
        }
        $scope.scorePopup = function (scoreId){
        	 $uibModal.open({
                 templateUrl: 'app/entities/score/score-dialog.html',
                 controller: 'ScoreDialogController',
                 controllerAs: 'vm',
                 backdrop: 'static',
                 size: 'lg',
                 resolve: {
                     entity: ['Score', function(Score) {
                       //  return Score.get({id : $stateParams.id}).$promise;
                    	 return Score.get({id :scoreId}).$promise;
                     }],
                     translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                         $translatePartialLoader.addPart('score');
                         $translatePartialLoader.addPart('global');
                         return $translate.refresh();
                     }]
                 }
             }).result.then(function() {
               //  $state.go('score', null, { reload: 'score' });
             }, function() {
                // $state.go('^');
             });
        }

        vm.openCalendar = function($event, match) {
            $event.preventDefault();
            $event.stopPropagation();

            match.isOpen = true;
         };
         
         vm.enablePublicFacing = function(){
        	 RoundCommonUtils.publishRoundInPublic({id:vm.round.id, publicFlag: vm.round.publicFacing},onSuccessEnablePublic);
         };
         
         vm.updateMatchDate = function(id,date){
        	 console.log("id->" + id + " date ->" + date );
        	 MathCommonUtils.updateMatchDate({id:id,matchDate:date});
         };
         

         vm.updateMatchComment = function(id, comment){
        	 console.log("id->" + id + " comment->" + comment );
        	 MathCommonUtils.updateMatchComment({id:id, matchComment:comment});
         };

         vm.updateMatchFormation = function(id, match){
            console.log("id->" + id + " formation->" + match.formation );
            MathCommonUtils.updateMatchFormation({id:id, matchFormation:match.formation});
        };

        vm.updateMatchFormationTwo = function(id, match){
            console.log("id->" + id + " formation Two->" + match.formationTwo );
            MathCommonUtils.updateMatchFormationTwo({id:id, matchFormationTwo:match.formationTwo});
        };

        vm.updateMatchStreaming = function(id, match){
            console.log("id->" + id + " streaming->" + match.streaming );
            MathCommonUtils.updateMatchStreaming({id:id, matchStreaming:match.streaming});
        };

        vm.updateMatchNowPlaying = function(id, match){
            console.log("id->" + id + " nowplaying->" + match.nowPlaying );
            MathCommonUtils.updateMatchNowPlaying({id:id, matchnowplaying:match.nowPlaying});
        };

         vm.updateMatchStreamDescription = function(id, streamDescription){
        	 console.log("id->" + id + " Stream Description->" + streamDescription );
        	 MathCommonUtils.updateMatchStreamDescription({id:id, matchStreamDescription:streamDescription});
         };
        
         vm.updateLobbyCode = function(id, lobbyCode){
            console.log("id->" + id + " lobbyCode ->" + lobbyCode );
            MathCommonUtils.updateMatchLobbyCode({id:id, matchLobbyCode:lobbyCode});
        };

         function onSuccessEnablePublic (result) {
         	console.log("EnableFlag" +result);
         	
         	
         }
         
         $scope.logEditRoundName = function(msg, value) {
        	    console.log(msg, value);
        	    if(msg==='SAVE'){
        	    	RoundCommonUtils.updateRoundName({id:vm.round.id, newRoundName: vm.round.name},onSuccessEnablePublic);
        	    }
         };

         $scope.updateGameProgresInRound = function( value) {
            console.log(value);            
            RoundCommonUtils.updateGameProgressInRound({id:vm.round.id, newGameProgress: vm.round.gameProgress},onSuccessEnablePublic);
        };

        $scope.updateGameProgressInMatch = function(newValue, match){   
            
            MathCommonUtils.updateGameProgressInMatch({id:match.id, newGameProgress: match.gameProgress});
        };

        $scope.updateMatchMakeBye = function (  match){
            //console.log("Current Value" + JSON.stringify(scores));
            $scope.matchMakeByeUpdated = match.id;   
               $("#spinner-bye-" +  $scope.matchMakeByeUpdated).show();  
            MathCommonUtils.updateMatchMakeBye({id:match.id}, onUpdateMakeByeSuccess);           	
        	
        };

        function onUpdateMakeByeSuccess (result) {           
            if(result && $scope.matchMakeByeUpdated){                
                $("#spinner-bye-" +  $scope.matchMakeByeUpdated).hide();               
            }
        }

        
        //Force to show on top
        $window.scrollTo(0, 0);  
    }
})();
