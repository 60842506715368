(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('TournamentDetailController', TournamentDetailController);

    TournamentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Tournament', 'Organization', 'Team', 'Player', 'Round','$window','CurrentNavigationApp','StandingsByTournamentSearch','TournamentBracket','DataUtils'];

    function TournamentDetailController($scope, $rootScope, $stateParams, previousState, entity, Tournament, Organization, Team, Player, Round,$window,CurrentNavigationApp,StandingsByTournamentSearch,TournamentBracket,DataUtils) {
        var vm = this;

        vm.tournament = entity;
        vm.previousState = previousState.name;
        
        CurrentNavigationApp.addTournament(vm.tournament.id,vm.tournament.name, vm.tournament.playersOrTeams, vm.tournament.tournamentType);
        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();
        
        
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        
        if ($scope.currentNavigation != null && $scope.currentNavigation.Tournament_id != null){
        	StandingsByTournamentSearch.query({id:$scope.currentNavigation.Tournament_id},onSuccess,onError);
   	 		function onSuccess(data) {
                 $scope.standings = data;
   	 		}

   	 		function onError(error) {
   	 			AlertService.error(error.data.message);
   	 		}
   	 		
   	 		if ( vm.tournament.tournamentType =='ROUND_ROBIN' || vm.tournament.tournamentType =='CUSTOM_ROUND_ROBIN' || vm.tournament.tournamentType =='EMLS_ROUND_ROBIN' || vm.tournament.tournamentType ==='GSL_STYLE' || vm.tournament.tournamentType =='VALORANT_CUSTOM_ROUND_ROBIN') {
	   	 		TournamentBracket.groupsStandingsRoundRobin({id:$scope.currentNavigation.Tournament_id},onSuccessRoundRobin,onErrorRoundRobin);
	   	 		function onSuccessRoundRobin(data) {
	   	 				$scope.standingsRoundRobin = data;
	   	 		}
	
	   	 		function onErrorRoundRobin(error) {
	   	 			AlertService.error(error.data.message);
	   	 		}
   	 		}

            if(vm.tournament.tournamentType =='LEADERBOARD' && vm.tournament.battleRoyal){
                TournamentBracket.getStandingBRBasedInPublicFacing({id:$scope.currentNavigation.Tournament_id},onSuccessBattleRoyalStandings,onErrorBattleRoyalStandings);
	   	 		function onSuccessBattleRoyalStandings(data) {
	   	 				$scope.standingsBattleRoyal = data;
	   	 		}
	
	   	 		function onErrorBattleRoyalStandings(error) {
	   	 			AlertService.error(error.data.message);
	   	 		}

                $scope.getTotalPointBrStndings = function( brRounds){
                    var totalPointsValue = 0;
                        if (brRounds){
                            angular.forEach(brRounds, function(brItem, index) {                    
                                if (brItem && brItem.bestRound){            
                                    totalPointsValue += brItem.totalpoints;
                                }                                
                            });
                        }
                        return totalPointsValue;
                }
            }
    	}	
        var unsubscribe = $rootScope.$on('ldtpApp:tournamentUpdate', function(event, result) {
            vm.tournament = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        $scope.goBack = function(){      	
           	$window.history.back();
        }
        
        vm.randomTournament = function (){
            if (confirm('Do you want to randomize the First Round?')){
                TournamentBracket.random({id:  vm.tournament.id},onSuccessRandomFree);
                
                function onSuccessRandomFree(data) {
                    $window.location.reload();
                   }
   
        	   
            }
        };
        
        vm.seedTournament = function (){
        	var r = confirm("Confirm that you want to generate the Seed\n" +
        			"WARNING! This Process MUST TO BE Execute One, next times the Seed Value will be Reset \n" +
        			" and you will need to fill the Seed Value");
        	if (r == true) {
        		TournamentBracket.seed({id:  vm.tournament.id});        	
        		$window.location.reload();
        	}
        	
        };
        
        vm.blockTournament = function (){
        	TournamentBracket.block({id:  vm.tournament.id});        	
        	$window.location.reload();        
        	
        };
        
        vm.saveBraketTournament = function (){
        	var jsonString = $window.getBracketData();
        	console.log ('Data - >' + jsonString);
        	TournamentBracket.saveBracket({id:  vm.tournament.id, jsonBracket:jsonString});
        	$window.location.reload();
        	
        
        };
        vm.saveBraketTournamentDouble = function (){
        	var jsonString = $window.getBracketDataDouble();
        	console.log ('Data - >' + jsonString);
        	TournamentBracket.saveBracket({id:  vm.tournament.id, jsonBracket:jsonString});
        	$window.location.reload();
        	
        
        };
        
       if (vm.tournament.jsonBracket != null){
        if ( vm.tournament.tournamentType =='SINGLE_ELIMINATION' || vm.tournament.tournamentType =='EMLS_SINGLE_ELIMINATION' || vm.tournament.tournamentType =='DOUBLE_ELIMINATION' ){
        //Populate Participants
        var typeParticipants = vm.tournament.playersOrTeams;
        var teams = vm.tournament.teams;
        var players = vm.tournament.players;
        var tempList = null;
        var teamsBracketsAutoComplete =[];
        
        if (typeParticipants =='PLAYERS'){
        	tempList = vm.tournament.players;
        }else{
        	tempList = vm.tournament.teams;
        }
        
        angular.forEach(tempList, function(item, index) {
    	
    		teamsBracketsAutoComplete.push(item.name);
    		
    	});
              
        console.log(vm.tournament.playersOrTeams);
        console.log(vm.tournament.jsonBracket);
      
        	$window.createBracket(
        		JSON.parse(vm.tournament.jsonBracket), 
        			vm.tournament.tournamentType,teamsBracketsAutoComplete);
        	
        }
    }
   /* if (  vm.tournament.tournamentType ==='DOUBLE_ELIMINATION' ){
    	var teamsBracketsAutoComplete =[];
    	var tempList = null;
    	var typeParticipants = vm.tournament.playersOrTeams;
        
        if (typeParticipants =='PLAYERS'){
        	tempList = vm.tournament.players;
        }
        
        angular.forEach(tempList, function(item, index) {
    	
    		teamsBracketsAutoComplete.push(item.gamerTag);
    		
    	});
       	
       	$window.createBracketDouble(
           		JSON.parse(vm.tournament.jsonBracket), 
           			vm.tournament.tournamentType,teamsBracketsAutoComplete);
    }*/
       function onSuccessEnablePublic (result) {
        	
        	
        	
        }
       $scope.logEditGroupNameRoundRobin = function(msg, value, groupId) {
   	    console.log(msg, value, groupId);
   	    if(msg==='SAVE'){
   	    	TournamentBracket.updateGroupNameRoundRobin({id:groupId, groupName: value},onSuccessEnablePublic);
   	    }
    };
     
  }
})();
