(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('round', {
            parent: 'entity',
            url: '/round',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.round.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/round/rounds.html',
                    controller: 'RoundController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('round');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('round-detail', {
            parent: 'round',
            url: '/round/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.round.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/round/round-detail.html',
                    controller: 'RoundDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('round');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Round', function($stateParams, Round) {
                    return Round.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'round',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('round-detail-round-robin', {
            parent: 'round',
            url: '/round-robin/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.round.detail.roundrobin.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/round/round-detail-round-robin.html',
                    controller: 'RoundDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('round');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Round', function($stateParams, Round) {
                    return Round.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'round',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('round-detail-emls-se', {
            parent: 'round',
            url: '/round-emls-rr/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.round.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/round/round-detail-emls-se.html',
                    controller: 'RoundDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('round');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Round', function($stateParams, Round) {
                    return Round.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'round',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('round-detail-round-robin-valorant', {
            parent: 'round',
            url: '/round-robin-valorant/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.round.detail.roundrobinvalorant.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/round/round-detail-rr-valorant.html',
                    controller: 'RoundDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('round');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Round', function($stateParams, Round) {
                    return Round.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'round',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('round-edit', {
            parent: 'round',
            url: '/round/{id}/edit',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.round.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/round/round-rework.html',
                    controller: 'RoundReworkController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('round');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Round', function($stateParams, Round) {
                    return Round.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'round',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('round-detail.edit', {
            parent: 'round-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/round/round-dialog.html',
                    controller: 'RoundDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Round', function(Round) {
                            return Round.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('round.new', {
            parent: 'round',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/round/round-dialog.html',
                    controller: 'RoundDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('round', null, { reload: 'round' });
                }, function() {
                    $state.go('round');
                });
            }]
        })
        .state('round.edit', {
            parent: 'round',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/round/round-dialog.html',
                    controller: 'RoundDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Round', function(Round) {
                            return Round.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('round', null, { reload: 'round' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('round.delete', {
            parent: 'round',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal','CurrentNavigationApp',function($stateParams, $state, $uibModal,CurrentNavigationApp) {
                $uibModal.open({
                    templateUrl: 'app/entities/round/round-delete-dialog.html',
                    controller: 'RoundDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Round', function(Round) {
                            return Round.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                	var navObj = CurrentNavigationApp.getCurrentNavigation();
                	$state.go('tournament-detail',{id:navObj.Tournament_id }, { reload: 'tournament' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('round-leadeboard', {
            parent: 'round',
            url: '/round-leaderboard/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.round.detail.title'

            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/round/round-leaderboard.html',
                    controller: 'RoundLeaderboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('round');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Round','TournamentBracket', function($stateParams, Round, TournamentBracket) {
                   //
                   console.log("Into call ");
                   return TournamentBracket.getCompleteInfoRoundEventFromTournament({id : $stateParams.id}).$promise;
                   //return Round.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'round',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('round-best-of', {
            parent: 'round',
            url: '/round-best-of/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ldtpApp.round.detail.title'

            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/round/round-best-of.html',
                    controller: 'RoundBestOfController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('round');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Round','TournamentBracket', function($stateParams, Round, TournamentBracket) {
                    return Round.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'round',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('round-scan', {
           //parent: 'round',
        	 parent: 'round-detail',
           // url: '/{id}/scan',
        	 // url: '/scan',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal','CurrentNavigationApp',function($stateParams, $state, $uibModal,CurrentNavigationApp) {
                $uibModal.open({
                    templateUrl: 'app/entities/round/round-scan-matches.html',
                    controller: 'RoundScanMatchesController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Round','TournamentBracket', function(Round,TournamentBracket) {
                        	var navObj = CurrentNavigationApp.getCurrentNavigation();
                        	console.log("Tid" + navObj.Tournament_id);
                        	return TournamentBracket.scanMatches({id:  navObj.Tournament_id}).$promise;;
                          //  return Round.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {

                	//$state.go('tournament-detail',{id:navObj.Tournament_id }, { reload: 'tournament' });
                	$state.go('round-detail',$stateParams,{ reload: 'tournament' });
                }, function() {
                	$state.go('round-detail',$stateParams,{ reload: 'tournament' });
                });
            }]
        });
    }

})();
