(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .factory('RoundSearch', RoundSearch);

    RoundSearch.$inject = ['$resource'];

    function RoundSearch($resource) {
        var resourceUrl =  'api/_search/rounds/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
