(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('TeamDetailController', TeamDetailController);

    TeamDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Team', 'Organization', 'Player', 'Tournament','$window','TeamHistoryByTournament','CurrentNavigationApp','AlertService','TeamScoreBoardByTournament'];

    function TeamDetailController($scope, $rootScope, $stateParams, previousState, entity, Team, Organization, Player, Tournament,$window,TeamHistoryByTournament,CurrentNavigationApp,AlertService,TeamScoreBoardByTournament) {
        var vm = this;

        vm.team = entity;
        vm.previousState = previousState.name;
        
        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();
        var navObj = CurrentNavigationApp.getCurrentNavigation();
        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();
        /*
        if (navObj != null && navObj.Tournament_id != null  ){
        	
        	TeamHistoryByTournament.query({tourId:navObj.Tournament_id,teamId:vm.team.id},onSuccess,onError);
        	 function onSuccess(data) {
                 
                 $scope.teamHistory = data;
             }

             function onError(error) {
                 AlertService.error(error.data.message);
             }
             
             TeamScoreBoardByTournament.query({tourId:navObj.Tournament_id,teamId:vm.team.id},onSuccessBoard,onErrorBoard);
             function onSuccessBoard(data) {
                
                 $scope.teamScoreBoard = data;
             }

             function onErrorBoard(error) {
                 AlertService.error(error.data.message);
             }
        	
        }
        */

        var unsubscribe = $rootScope.$on('ldtpApp:teamUpdate', function(event, result) {
            vm.team = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        $scope.goBack = function(){      	
           	$window.history.back();
        }
    }
})();
