(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('TournamentRoundEventScheduleController', TournamentRoundEventScheduleController);

    TournamentRoundEventScheduleController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Tournament', 'Organization', 'Team', 'Player', 'Round','$window','CurrentNavigationApp','StandingsByTournamentSearch','TournamentBracket','Seed'];

    function TournamentRoundEventScheduleController($scope, $rootScope, $stateParams, previousState, entity, Tournament, Organization, Team, Player, Round,$window,CurrentNavigationApp,StandingsByTournamentSearch,TournamentBracket,Seed) {
        var vm = this;

        vm.tournament = entity;
        vm.previousState = previousState.name;

        $scope.roundEventList = [];

        TournamentBracket.getRoundEventFromTournament({id:  vm.tournament.id}, onGetRoundEventSuccess, onSaveError);

      
        
        CurrentNavigationApp.addTournament(vm.tournament.id,vm.tournament.name, vm.tournament.playersOrTeams, vm.tournament.tournamentType);
        $scope.currentNavigation = CurrentNavigationApp.getCurrentNavigation();
        
      	
        var unsubscribe = $rootScope.$on('ldtpApp:tournamentUpdate', function(event, result) {
            vm.tournament = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        vm.isSaving = false;
        
        
        
       
   
        vm.save = function () {    
            if (vm.tournament.id !== null) {                              
                TournamentBracket.saveRoundEventFromTournament({id:  vm.tournament.id},  $scope.roundEventList,onSaveSuccess, onSaveError);
            } 
           
        }
        

        function onSaveSuccess (result) {
           // $scope.$emit('ldtpApp:tournamentUpdate', result);
           // $uibModalInstance.close(result);
            vm.isSaving = false;
            $window.location= "/#/tournament/tournament/" +vm.tournament.id;  
            $window.location.reload();
        }

        function onGetRoundEventSuccess (result) {
            if (result !== null && result.length > 0){
                $scope.roundEventList = result;
            }else {
                if ( vm.tournament.tournamentType === 'SWISS_STYLE'){
                    if (vm.tournament.matchGamesInSwiss !== null){                    
                        for (var index=0; index < vm.tournament.matchGamesInSwiss; index++){
                            var item = {roundDisplayName:null,eventDescription: null, eventTime:null};
                            $scope.roundEventList.push(item);
                        }                    
                    }
                }
                if ( vm.tournament.tournamentType === 'SINGLE_ELIMINATION'){
                    if (vm.tournament.totalParticipants !== null && (vm.tournament.totalParticipants % 2 === 0 )){
                            var localParticipant = vm.tournament.totalParticipants;
                            var nRounds = 1;
                            for (;localParticipant>=2;){
                                if ((localParticipant / 2 ) >=1){
                                    ++nRounds;
                                    localParticipant = localParticipant/ 2;
                                }
                            }
                            for (var index=0; index < nRounds; index++){
                                var item = {roundDisplayName:null,eventDescription: null, eventTime:null};
                                $scope.roundEventList.push(item);
                            } 
                    }
                }
                
            }
            if ( vm.tournament.tournamentType === 'FREE_ROUNDS' || vm.tournament.tournamentType === 'BEST_OF_FREE'){
                if (vm.tournament != null && vm.tournament.rounds != null && vm.tournament.rounds.length > 0){
                    var currentROundsInFree = vm.tournament.rounds.length;
                    var currentEventValue =  $scope.roundEventList.length;
                    if (currentROundsInFree > currentEventValue){
                        for (var indexFree=0; indexFree < currentROundsInFree - currentEventValue; indexFree++){
                            var item = {roundDisplayName:null,eventDescription: null, eventTime:null};
                            $scope.roundEventList.push(item);
                        } 
                    }
                }
                
            }
           
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
 
    }
})();
