(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .factory('MatchsSearch', MatchsSearch);

    MatchsSearch.$inject = ['$resource'];

    function MatchsSearch($resource) {
        var resourceUrl =  'api/_search/matchs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
