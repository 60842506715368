(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('ScoreBoardDetailController', ScoreBoardDetailController);

    ScoreBoardDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ScoreBoard', 'Tournament', 'Player', 'Team','$window'];

    function ScoreBoardDetailController($scope, $rootScope, $stateParams, previousState, entity, ScoreBoard, Tournament, Player, Team,$window) {
        var vm = this;

        vm.scoreBoard = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('ldtpApp:scoreBoardUpdate', function(event, result) {
            vm.scoreBoard = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
        $scope.goBack = function(){      	
           	$window.history.back();
        }
    }
})();
