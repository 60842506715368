(function() {
    'use strict';
    angular
        .module('ldtpApp')
        .factory('GameSeason', GameSeason);

    GameSeason.$inject = ['$resource', 'DateUtils'];

    function GameSeason ($resource, DateUtils) {
        var resourceUrl =  'api/game-seasons/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'update': { method:'PUT' }
        });
    }
})();
