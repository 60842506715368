(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('RoundDeleteController',RoundDeleteController);

    RoundDeleteController.$inject = ['$uibModalInstance', 'entity', 'Round','TournamentBracket','CurrentNavigationApp','$scope'];

    function RoundDeleteController($uibModalInstance, entity, Round, TournamentBracket,CurrentNavigationApp,$scope) {
        var vm = this;

        vm.round = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        $scope.divLoading = 'none';

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
        	$scope.divLoading = 'block';
            Round.delete({id: id}, onSaveSuccess, onSaveError);
        }
        
        function onSaveSuccess (result) {    
        	var navObj = CurrentNavigationApp.getCurrentNavigation();
        	if (navObj != null && navObj.Tournament_id != null){
        		//alert ("Value of TOurnament" + navObj.Tournament_id);
        		//TournamentBracket.recalculateStandings({id:navObj.Tournament_id},onSaveSuccessRecalculate,onSaveErrorRecalculate);
        		onSaveSuccessRecalculate(null);
        	}else{
        		alert("The Session Expired");
        		 $scope.divLoading = 'none';
        	}      	
        	 
           
        }

        function onSaveError () {
        	 $scope.divLoading = 'none';
        }
        
        function onSaveSuccessRecalculate (result) {  
        	 $scope.divLoading = 'none';
        	 $uibModalInstance.close(true);
        }
        function onSaveErrorRecalculate () {
        	 $scope.divLoading = 'none';
        }

    }
})();
