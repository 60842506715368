(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('TournamentDialogController', TournamentDialogController);

    TournamentDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Tournament', 'Organization', 'Team', 'Player', 'Round','PlayerByOrganizationSearch','TeamByOrganizationSearch','GameEvent'];

    function TournamentDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Tournament, Organization, Team, Player, Round,PlayerByOrganizationSearch,TeamByOrganizationSearch,GameEvent) {
        var vm = this;

        vm.tournament = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.organizations = Organization.query();
        vm.gameEvents = GameEvent.query({
            page: 0,
            size: 10000,
            sort:  ['name' + ',' + 'asc']
            },onGetEventSuccess);
        
        if (!vm.tournament.teams){
        	vm.tournament.teams  =[];
        }
        vm.teams = vm.tournament.teams;//Team.query();
        
        if (!vm.tournament.players){
        	vm.tournament.players  =[];
        }
        vm.players = vm.tournament.players; //Player.query();
        vm.rounds = Round.query();
        
        $scope.PlayerByOrganizationAvailable = Player.query({
										            page: 0,
										            size: 10000,
										            sort:  ['gamerTag' + ',' + 'asc']
										        },onGetPlayersSuccess);//PlayerByOrganizationSearch.query(onGetPlayersSuccess);
        $scope.TeamByOrganizationAvailable = Team.query({
                                                page: 0,
                                                size: 10000,
                                                sort:  ['name' + ',' + 'asc']
                                                },onGetPlayersSuccess);//TeamByOrganizationSearch.query(onGetTeamsSuccess);

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.tournament.id !== null) {
                Tournament.update(vm.tournament, onSaveSuccess, onSaveError);
            } else {
                Tournament.save(vm.tournament, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ldtpApp:tournamentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        function onGetEventSuccess (result) {
            
            
        }

        function onGetPlayersSuccess (result) {
            
            removeListFromArray($scope.PlayerByOrganizationAvailable,vm.players);
        }
        function onGetTeamsSuccess (result) {
            
            removeListFromArray($scope.TeamByOrganizationAvailable,vm.teams);
        }
        
        $scope.moveItem = function (item, from, to) {
           
            
            if (item != null){
            	var itemLength = item.length;
            	for (var index = 0; index < itemLength; index++){
            		//Here from is returned as blank and to as undefined
            		var itemElemen = item[index]; 
            		var found = from.find(  function(itemArray){
            							return itemArray.id === itemElemen.id
            						}
            				);

            		var idx=from.indexOf(found);
            		if (idx != -1) {
            			from.splice(idx, 1);
            			to.push(itemElemen);      
            		}
            	}
           }
        };
        
        $scope.moveAll = function (from, to) {

            
            //Here from is returned as blank and to as undefined

            angular.forEach(from, function(item) {
                to.push(item);
            });
            from.length = 0;
        };
        
        function removeListFromArray(listTotal, listExcept){
        	angular.forEach(listExcept, function(item, index) {
        		        		  
        		  var found = listTotal.find(  function(itemArray){
									return itemArray.id === item.id
								}
        		  			);

        		  var idx= listTotal.indexOf(found);
        		  if (idx != -1) {
        			  	listTotal.splice(idx, 1);
        			 // 	to.push(item);      
        		  }
        		  
        		  
        		});
        	
        };

        vm.datePickerOpenStatus.tournamentDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
