(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('GameSeasonDeleteController',GameSeasonDeleteController);

    GameSeasonDeleteController.$inject = ['$uibModalInstance', 'entity', 'GameSeason'];

    function GameSeasonDeleteController($uibModalInstance, entity, GameSeason) {
        var vm = this;

        vm.gameSeason = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            GameSeason.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
