(function() {
    'use strict';

    angular
        .module('ldtpApp')
        .controller('ScoreBoardDialogController', ScoreBoardDialogController);

    ScoreBoardDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ScoreBoard', 'Tournament', 'Player', 'Team'];

    function ScoreBoardDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ScoreBoard, Tournament, Player, Team) {
        var vm = this;

        vm.scoreBoard = entity;
        vm.clear = clear;
        vm.save = save;
        vm.tournaments = Tournament.query();
        vm.players = Player.query();
        vm.teams = Team.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.scoreBoard.id !== null) {
                ScoreBoard.update(vm.scoreBoard, onSaveSuccess, onSaveError);
            } else {
                ScoreBoard.save(vm.scoreBoard, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ldtpApp:scoreBoardUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
